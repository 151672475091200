import React from 'react';

function NoChat() {
    return (
        <svg id="noBidi" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <path
                id="noBidi-2"
                data-name="noBidi"
                d="m174.88,169.47l-16.93-16.93c7.52-3.06,12.85-10.44,12.85-19.05v-66.97c0-11.34-9.23-20.57-20.57-20.57H51.35l-20.82-20.82c-1.49-1.49-3.92-1.49-5.41,0-1.49,1.49-1.49,3.92,0,5.41l16.93,16.93c-7.52,3.06-12.85,10.44-12.85,19.05v66.97c0,11.34,9.23,20.57,20.57,20.57h98.88l20.82,20.82c.75.75,1.73,1.12,2.71,1.12s1.96-.37,2.71-1.12c1.49-1.49,1.49-3.92,0-5.41Zm-24.65-115.87c7.12,0,12.92,5.79,12.92,12.92v66.97c0,6.62-5,12.08-11.42,12.82l-18.03-18.03v-8.87c-.01-11.97-9.76-21.73-21.74-21.74v.02h-3.84c7.28-3.15,12.38-10.39,12.38-18.81,0-11.3-9.19-20.49-20.49-20.49-9.82,0-18.04,6.94-20.03,16.18l-20.96-20.96h91.22Zm-76.26,91.85v-26.03c.01-7.76,6.34-14.07,14.09-14.08h11.87l26.1,26.1v14h-52.07Zm13.19-66.57c0-7.08,5.76-12.84,12.84-12.84s12.84,5.76,12.84,12.84-5.76,12.84-12.84,12.84h0c-1.15,0-2.26-.16-3.32-.45l-9.07-9.07c-.29-1.06-.45-2.17-.45-3.32Zm23.59,26.47h1.19v-.02c7.76,0,14.08,6.33,14.09,14.09v1.21l-15.28-15.28Zm-73.9,28.14v-66.97c0-6.62,5-12.08,11.42-12.82l32.66,32.66c2,5.08,5.95,9.18,10.94,11.34h-3.83c-11.96.01-21.72,9.75-21.74,21.72v26.99h-16.54c-7.12,0-12.92-5.79-12.92-12.92Zm96.83,12.92v-7.3l7.3,7.3h-7.3Z"
            />
        </svg>
    );
}

export default NoChat;
