import { callerStore } from '../store/CallerStore';
import { DEBUG, GPS_INTERVAL } from '../config';
import { addLogDispatch } from '../redux/actions/logs';
import { errorLog, dataLog } from '../helper/logging';
import { sendNewLocation } from './backendApi';
import { getURLParams } from '../helper/rtcFlowHandling';

let locationInterval = null;
let lastGeoLocation = {
    accuracy: null,
    latitude: null,
    longitude: null,
};

/**
 * start the gps interval
 */
export const startGPSInterval = async () => {
    if (navigator.geolocation) {
        locationInterval = setInterval(checkGPSPermissions, GPS_INTERVAL);
    } else {
        sendNotAvaliable();
    }
};

/**
 * clear the gps interval
 */
export const clearGPSInterval = () => {
    clearInterval(locationInterval);
};

/**
 * create the coordination message object
 * @param object} data
 */
function createCoords(data) {
    if (data && data.coords) {
        const message = {
            data: 'coords',
            accuracy: data.coords.accuracy,
            altitude: data.coords.altitude,
            altitudeAccuracy: data.coords.altitudeAccuracy,
            heading: data.coords.heading,
            latitude: data.coords.latitude,
            longitude: data.coords.longitude,
            speed: data.coords.speed,
            timestamp: data.timestamp,
        };
        return message;
    }
    return {
        data: 'coordsError',
        message: 'error while getting location',
        error: 'no choords available',
    };
}

/**
 * check for given gps permissions by the user and decide what to do
 */
export const checkGPSPermissions = async () => {
    if (navigator.permissions) {
        const permissionStatus = await navigator.permissions.query({
            name: 'geolocation',
        });
        if (permissionStatus.state === 'granted') {
            const location = await getLocationWithHighAccuracy();
            setNewLocationAndSend(location);
        }
        if (permissionStatus.state === 'prompt') {
            if (DEBUG) addLogDispatch(['waiting for permission by user']);
            const location = await getLocationWithHighAccuracy(false);
            setNewLocationAndSend(location);
        }
        if (permissionStatus.state === 'denied') {
            sendPermissionDenied();
            const { sessionId } = getURLParams();
            dataLog({
                message: 'gps permission denied',
                eventId: 'LOCATION',
                sessionId: sessionId,
            });
            if (DEBUG) addLogDispatch(['gps permission denied by user']);
        }
    } else {
        const location = await getLocationWithHighAccuracy();
        setNewLocationAndSend(location);
    }
};

/**
 * get the current position with high accuracy or not
 * @param {boolean} highAccuracy - use high accuracy
 * @returns {Promise} get position promise
 */
var getCurrentPosition = highAccuracy => {
    const options = { enableHighAccuracy: highAccuracy };

    return new Promise(function(resolve, reject) {
        navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
};

/**
 * get the location with high accuracy or not - wrapper for position
 * @param {boolean} highAccuracy - use high accuracy
 * @returns {object} coords message object
 */
const getLocationWithHighAccuracy = async (highAccuracy = true) => {
    const { sessionId } = getURLParams();
    const geoLocation = await getCurrentPosition(highAccuracy).catch(error => {
        sendError(error.message + '(' + error.code + ')');
        errorLog({
            message: 'getting gps location failed',
            error: error,
            eventId: 'LOCATION',
            sessionId: sessionId,
        });
        if (DEBUG) addLogDispatch(['getting gps location failed', error]);
    });

    return createCoords(geoLocation);
};

/**
 * set a new location
 * @param {object} location current location
 */
const setNewLocationAndSend = location => {
    if (
        location &&
        (location.accuracy !== lastGeoLocation.accuracy ||
            location.longitude !== lastGeoLocation.longitude ||
            location.latitude !== lastGeoLocation.latitude)
    ) {
        lastGeoLocation = location;
        sendLocation(location);
    }
};

/**
 * send location message with webrtc
 * @param {object} location current location
 */
const sendLocation = location => {
    if (DEBUG) addLogDispatch(['sending location', location]);
    callerStore.sendMessage(location);
    location.sessionId = sendNewLocation(location);
};

/**
 * send error message with webrtc
 * @param {object} error
 */
const sendError = error => {
    clearGPSInterval();

    const message = {
        data: 'coordsError',
        message: 'error while getting location',
        error,
    };

    callerStore.sendMessage(message);
};

/**
 * send not available message with webrtc
 */
const sendNotAvaliable = () => {
    clearGPSInterval();

    const message = {
        data: 'coordsNotAvail',
        message: 'geolocation api not available',
    };
    callerStore.sendMessage(message);
};

/**
 * send permission denied message with webrtc
 */
const sendPermissionDenied = () => {
    clearGPSInterval();

    const message = {
        data: 'coordsPermissionDenied',
        message: 'permission denied by user',
    };
    callerStore.sendMessage(message);
};
