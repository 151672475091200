import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './Invitation.scss';
import { createKpiLog, replaceText } from '../../helper/helper';
import { connect } from 'react-redux';
import Loading from '../Icons/LoadingSpinner';
import { addNotificationAndShowDispatch } from '../../redux/actions/notifications';
import { DISPLAY_ONLY_IN_SESSION } from '../../config';
import Send from '../Icons/Send';
import { Copy } from '../Icons/Copy';
import { getCurrentInviteEmailText, sendInvitation } from '../../api/backendApi';
import { getURLParams } from '../../helper/rtcFlowHandling';

interface InvitationProps {
  texts: any;
  closePanelHandler: any;
}

const Invitation: React.FC<InvitationProps> = ({ texts, closePanelHandler }) => {
  const [sendTo, setSendTo] = useState('');
  const [emailText, setEmailText] = useState('');
  const [conferenceId, setConferenceId] = useState('');
  const [url, setUrl] = useState<string | null>(null);

  const [emailAddresses, setEmailAddresses] = useState<string[]>([]);
  const [emailAddressFieldHasError, setEmailAddressFieldHasError] = useState(false);
  const [emailBodyHasError, setEmailBodyHasError] = useState(false);

  const [showLinkCopiedSuccessfully, setShowLinkCopiedSuccessfully] = useState(false);
  const [showEmailSentSuccessfully, setShowEmailSentSuccessfully] = useState(false);
  const [showEmailSentFailed, setShowEmailSentFailed] = useState(false);

  const [sendButtonIsDisabled, setSendButtonIsDisabled] = useState(false);

  const emailAddressFieldRegex = /^[a-zA-Z0-9@.,;\s\-_]+$/;
  const emailBodyTextRegex = /[/\\:<>]/g;

  let emailAddressFieldClasses = `add-recipient-field ${emailAddressFieldHasError ? 'add-recipient-field--hasError' : ''}`;
  let emailAddDescriptionClasses = `invitation__add-description-field ${emailBodyHasError ? 'invitation__add-description-field--hasError' : ''}`;

  let sendToRef = useRef<HTMLInputElement>(null);
  let emailTextRef = useRef<HTMLTextAreaElement>(null);
  let showEmailSentTimeout = null;

  const handleRecipientInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    validateEmailAddresses(e);
    if (!emailAddressFieldHasError) {
      let emailAddresses = e.target.value.replace(/,/g, ';');
      let updatedAddresses = emailAddresses.split(';');
      setEmailAddresses(updatedAddresses);
      setSendTo(e.target.value);
    }
  };

  const handleEmailTextInput = () => {
    setSendTo(sendToRef.current!.value);
    setEmailText(emailTextRef.current!.value);
  };

  const calculateConferenceId = () => {
    const { callerId, sessionId } = getURLParams();
    const conferenceId = callerId + '/' + sessionId;
    setConferenceId(conferenceId);
  };

  const copyLink = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      try {
        navigator.clipboard.writeText(url!);
        setShowLinkCopiedSuccessfully(true);
        setTimeout(() => {
          setShowLinkCopiedSuccessfully(false);
        }, 2000);
      } catch {
        addNotificationAndShowDispatch('error.cpy_lnk_err', 'error', DISPLAY_ONLY_IN_SESSION);
      }
    }
  };

  const validateEmailAddresses = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length !== 0 && emailAddressFieldRegex.test(e.target.value)) {
      setEmailAddressFieldHasError(false);
    } else if (e.target.value.length !== 0 && !emailAddressFieldRegex.test(e.target.value)) {
      setEmailAddressFieldHasError(true);
    } else {
      setEmailAddressFieldHasError(false);
    }
  };

  const validateEmailBodyText = (): boolean => {
    if (emailBodyTextRegex.test(emailText)) {
      setEmailBodyHasError(true);
      return true;
    } else {
      setEmailBodyHasError(false);
      return false;
    }
  };

  const sendEmail = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (validateEmailBodyText()) {
      return;
    }

    if (!emailAddressFieldHasError && !emailBodyHasError) {
      let result = null;
      if (emailAddresses.length > 0) {
        setSendButtonIsDisabled(true);
        for (const email of emailAddresses) {
          result = await sendInvitation({
            to: email.replace(/ /g, ''),
            message: emailText,
            conference: conferenceId,
          }).catch(err => {
            console.log(err);
          });

          if (result) {
            const additionalStates = {
              0: email,
              1: emailText,
            };

            createKpiLog('infoShareInviteSent', '', additionalStates);
            setShowEmailSentSuccessfully(true);
            showEmailSentTimeout = setTimeout(() => {
              setShowEmailSentSuccessfully(false);
            }, 2000);
          } else {
            setShowEmailSentFailed(true);
            showEmailSentTimeout = setTimeout(() => {
              setShowEmailSentFailed(false);
            }, 2000);
          }
        }
        setTimeout(() => {
          setSendButtonIsDisabled(false);
          closePanelHandler();
        }, 2000);
      }
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(showEmailSentTimeout);
    };
  }, [showEmailSentTimeout]);

  useEffect(() => {
    const getAndSetEmailText = async () => {
      const emailText = await getCurrentInviteEmailText();
      if (emailText) {
        setEmailText(emailText);
      }
    };

    const prepAndSetConferenceLink = () => {
      let conferenceUrl = window.location.href.replace('disptchr', 'conference'); // eslint-disable-line
      setUrl(conferenceUrl);
    };

    getAndSetEmailText();
    calculateConferenceId();
    prepAndSetConferenceLink();
  }, []);

  return (
    <div className="invitation">
      <form onChange={handleEmailTextInput}>
        <div className="invitation__add-recipient__container">
          <span>{replaceText(texts, 'invitation.description')}</span>
          <div className={emailAddressFieldClasses}>
            <input id="phone" type="text" ref={sendToRef} onChange={handleRecipientInput} value={sendTo}></input>
            {emailAddressFieldHasError && <span>{replaceText(texts, 'invitation.email.error')}</span>}
          </div>
        </div>
        <div className={emailAddDescriptionClasses}>
          <textarea
            placeholder={replaceText(texts, 'invitation.placeholder')}
            ref={emailTextRef}
            onChange={() => {}}
            onKeyUp={validateEmailBodyText}
            value={emailText}></textarea>
          {emailBodyHasError && <span>{replaceText(texts, 'invitation.email.error')}</span>}
        </div>
        <div className="button__container">
          {emailAddressFieldHasError || emailBodyHasError ? (
            <button disabled className="btn btn--primary" onClick={sendEmail}>
              <Send />
              {replaceText(texts, 'invitation.send.button')}
            </button>
          ) : !sendButtonIsDisabled ? (
            <button className="btn btn--primary" onClick={sendEmail}>
              <Send />
              {replaceText(texts, 'invitation.send.button')}
            </button>
          ) : (
            <div className="alert-panel__textWrapper">
              {replaceText(texts, 'invitation.email.info')}
              <Loading />
            </div>
          )}
          <button className="btn btn--primary" onClick={copyLink}>
            <Copy />
            {replaceText(texts, 'invitation.copy.button')}
          </button>
        </div>
        <div className="alert-panel">
          <p className={`email-sent alert alert--success ${showEmailSentSuccessfully ? '' : 'email-sent--isHidden'}`}>
            {replaceText(texts, 'email.success.info')}
          </p>
          <p className={`email-sent alert alert--error ${showEmailSentFailed ? '' : 'email-sent--isHidden'}`}>
            {replaceText(texts, 'email.failed.info')}
          </p>
          <p className={`link-copied alert alert--success ${showLinkCopiedSuccessfully ? '' : 'link-copied--isHidden'}`}>
            {replaceText(texts, 'invitation.copy.info')}
          </p>
        </div>
      </form>
    </div>
  );
};

Invitation.propTypes = {
  texts: PropTypes.any,
  closePanelHandler: PropTypes.any,
};

const mapStateToProps = state => {
  return {
    texts: state.texts.texts,
  };
};

export default connect(mapStateToProps)(Invitation);