import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { store } from '../../store/DispatcherStore';

import ToggleSwitch from '../Globals/ToggleSwitch';
import ConnectionOverlay from '../Globals/ConnectionOverlay';

import './WebRTCSwitcher.scss';
import { addNotificationAndShowDispatch } from '../../redux/actions/notifications';
import {
    enableSnapshotDispatch,
    enableVideoDispatch,
    disableSnapshotDispatch,
    activateChatDispatcherDispatch,
    activateVideoDispatcherDispatch,
    deactivateChatDispatcherDispatch,
    deactivateGPSDispatch,
    deactivateSnapshotDispatch,
    deactivateVideoDispatcherDispatch,
    activateSnapshotDispatch,
    activateGPSDispatch,
    disablePointerDispatch,
    deactivatePointerDispatcherDispatch,
    activatePointerDispatcherDispatch,
    enablePointerDispatch,
    deactivateHDSendDispatch,
    activateHDSendDispatch,
    enableDrawDispatch,
    disableDrawDispatch,
    deactivateDrawDispatcherDispatch,
    activateDrawDispatcherDispatch,
    disableChatDispatch,
    enableChatDispatch,
    removeVideoStreamDispatch,
    dispatchCallerFileTransferEnded,
    dispatchCallerFileIsNotBusy,
    deactivateConferencingDispatch,
    activateConferencingDispatch,
    deactivateAudioStreamDispatcherDispatch,
    activateAudioStreamDispatcherDispatch,
    unmuteAudioDispatch,
    muteAudioDispatch,
    deactivateBidiDispatch,
    activateBidiDispatch,
    deactivateStreamRecordingDispatch,
    activateStreamRecordingDispatch,
    activateBidiBackgroundDispatch,
} from '../../redux/actions/application';

import { C_LOST, DISPLAY_ONLY_IN_SESSION, STREAM_RECORDING_LIMIT } from '../../config';
import { createKpiLog, isWebGLAvailable, replaceText } from '../../helper/helper';
import { dispatchDisallowPaintingDispatcher, dispatchShowScreenshotDialogueThen } from '../../redux/actions/paint';
import { removeStreamContainer } from '../../helper/streamHandling';
import {
    activateScreenSharingDispatcherDispatch,
    deactivateScreenSharingDispatcherDispatch,
    muteMicDispatcherDispatch,
    unmuteMicDispatcherDispatch,
} from '../../redux/actions/conferencing';
import { unpublishStreamAndRemoveFromRedux } from '../../helper/rtcFlowHandling';
import { resetAudioStreamPermissionDispatch } from '../../redux/actions/permissions';

/**
 * WebRTCSwitcher
 * ToggleSwitchers for all functions available.
 * Handles any dependencies on one another.
 *
 * @component ConnectionOverlay - shows waiting for connection until webRTC connection is established
 * @component ToggleSwitch - Simple toggle switch (on/off)
 */

class WebRTCSwitcher extends PureComponent {
    _audioPermissionCheckInterval = 0;
    _streamRecordingPermissionCheckInterval = 0;

    constructor(props) {
        super(props);
        this.state = {
            deactivateGPSDispatchHasRun: false,
            deactivateVideoDispatchHasRun: false,
            recordingLimitIsReached: false,
            streamRecordTimer: 0,
            webGLSupported: false,
            bidiHasBeenActivated: false,
        };

        if (store.connectedSession) {
            store.connectedSession.removeListener('contactMessage', this.handleContactMessage);
            store.connectedSession.on('contactMessage', this.handleContactMessage);
        }
    }

    handleContactMessage = e => {
        const message = JSON.parse(e.content);

        if (message && (message.data === 'coordsError' || message.data === 'coordsNotAvail' || message.data === 'coordsPermissionDenied')) {
            if (!this.state.deactivateGPSDispatchHasRun) {
                deactivateGPSDispatch();
                this.setState({
                    deactivateGPSDispatchHasRun: true,
                });
            }
        }
        if (message && message.data === 'coordsError') {
            addNotificationAndShowDispatch('error.gps_err', 'error', DISPLAY_ONLY_IN_SESSION);
        }
        if (message && message.data === 'coordsNotAvail') {
            addNotificationAndShowDispatch('error.gps_nt_avlbl', 'error', DISPLAY_ONLY_IN_SESSION);
        }
        if (message && message.data === 'coordsPermissionDenied') {
            addNotificationAndShowDispatch('error.gps_dsbld', 'error', DISPLAY_ONLY_IN_SESSION);
        }
        if (message && message.data === 'deviceInfo' && message.devices.length === 0) {
            addNotificationAndShowDispatch('error.vd_dsbld', 'error', DISPLAY_ONLY_IN_SESSION);
            deactivateVideoDispatcherDispatch();
            disablePointerDispatch();
            disableSnapshotDispatch();
            disableDrawDispatch();
        }

        if (message && message.data === 'videoNotAvail') {
            addNotificationAndShowDispatch('error.vd_dsbld', 'error', DISPLAY_ONLY_IN_SESSION);
            if (!this.state.deactivateVideoDispatchHasRun) {
                deactivateVideoDispatcherDispatch();
                this.setState({
                    deactivateVideoDispatchHasRun: true,
                });
            }
            removeStreamContainer();
            removeVideoStreamDispatch();
        }

        if (message && message.data === 'deviceInfo' && message.devices.length > 0) {
            enableVideoDispatch();
        }

        if (message && message.data === 'photoPermission' && message.permission === false) {
            deactivateSnapshotDispatch();
        }

        if (message && message.data === 'streamRecordingPermission' && message.permission === false) {
            deactivateStreamRecordingDispatch();
            clearInterval(this._streamRecordingPermissionCheckInterval);
        }
    };

    deactivateAllSwitches = () => {
        deactivateVideoDispatcherDispatch();
        deactivateChatDispatcherDispatch();
        deactivateGPSDispatch();
        deactivateSnapshotDispatch();
        deactivatePointerDispatcherDispatch();
    };

    toggleChat = () => {
        const currentActiveState = this.props.chatIsActive;
        currentActiveState ? deactivateChatDispatcherDispatch() : activateChatDispatcherDispatch();
    };
    toggleVideo = () => {
        const currentActiveState = this.props.videoIsActive;
        if (currentActiveState) {
            deactivateVideoDispatcherDispatch();
        } else {
            activateVideoDispatcherDispatch();
            createKpiLog('stateLiveVideoFeature', 'activated');
            this.setState({
                deactivateVideoDispatchHasRun: false,
            });
        }

        if (currentActiveState) {
            deactivateSnapshotDispatch();
            disableSnapshotDispatch();
            deactivatePointerDispatcherDispatch();
            disablePointerDispatch();
            disableDrawDispatch();
            deactivateDrawDispatcherDispatch();
            dispatchDisallowPaintingDispatcher();
            enableChatDispatch();
        } else {
            enableSnapshotDispatch();
            enablePointerDispatch();
            enableDrawDispatch();
        }
    };
    toggleSnapshot = () => {
        const currentActiveState = this.props.snapshotIsActive;
        if (currentActiveState) {
            deactivateSnapshotDispatch();
            deactivateHDSendDispatch();
        } else {
            activateSnapshotDispatch();
        }
    };
    toggleGPS = () => {
        if (!this.state.webGLSupported) {
            if (isWebGLAvailable()) {
                this.setState({
                    webGLSupported: true,
                });
            } else {
                addNotificationAndShowDispatch('error.webgl', 'error', DISPLAY_ONLY_IN_SESSION);
                return;
            }
        }

        const currentActiveState = this.props.gpsIsActive;
        if (currentActiveState) {
            deactivateGPSDispatch();
        } else {
            activateGPSDispatch();
            this.setState({
                deactivateGPSDispatchHasRun: false,
            });
        }
    };

    togglePointer = () => {
        const currentActiveState = this.props.pointerIsActive;
        currentActiveState ? deactivatePointerDispatcherDispatch() : activatePointerDispatcherDispatch();
    };

    toggleDraw = () => {
        const currentActiveState = this.props.drawIsActive;
        if (!currentActiveState) {
            activateDrawDispatcherDispatch();
        }

        if (currentActiveState) {
            if (this.props.photoPermission && this.props.snapshotFeature && this.props.isPaintingAllowed) {
                dispatchShowScreenshotDialogueThen(deactivateDrawDispatcherDispatch);
            } else {
                deactivateDrawDispatcherDispatch();
            }

            enableChatDispatch();
            if (this.props.wasChatActiveBefore) {
                activateChatDispatcherDispatch();
            }
            enablePointerDispatch();
            enableSnapshotDispatch();
        } else {
            disableChatDispatch();
            deactivateChatDispatcherDispatch();
            disablePointerDispatch();
            disableSnapshotDispatch();
            deactivatePointerDispatcherDispatch();
            deactivateSnapshotDispatch();
            dispatchDisallowPaintingDispatcher();
            dispatchCallerFileTransferEnded();
            dispatchCallerFileIsNotBusy();
        }
    };

    toggleHDSend = () => {
        const currentActiveState = this.props.hdSendIsActive;
        currentActiveState ? deactivateHDSendDispatch() : activateHDSendDispatch();
    };

    toggleAudioStream = () => {
        const currentActiveState = this.props.audioStreamIsActive;
        if (currentActiveState) {
            deactivateAudioStreamDispatcherDispatch();
        } else {
            if (this.props.audioStreamPermission === null || this.props.audioStreamPermission === false) {
                // Permission needs to be reset to check if dispatcher has reset browser permissions
                resetAudioStreamPermissionDispatch();
                this._audioPermissionCheckInterval = setInterval(this.checkIfAudioPermissionIsGranted, 500);
            }
            activateAudioStreamDispatcherDispatch();
        }
    };

    checkIfAudioPermissionIsGranted = () => {
        if (this.props.audioStreamPermission === false) {
            if (this.props.audioStreamIsActive) deactivateAudioStreamDispatcherDispatch();
            clearInterval(this._audioPermissionCheckInterval);
        }
        if (this.props.audioStreamPermission === true) {
            clearInterval(this._audioPermissionCheckInterval);
        }
    };

    toggleConferencing = () => {
        const currentActiveState = this.props.conferencingIsActive;
        if (currentActiveState) {
            store.sendDispatcherLeftToConferenceUsers();
            if (this.props.screenSharingIsActive) deactivateScreenSharingDispatcherDispatch();
            deactivateConferencingDispatch();
            if (!this.props.audioStreamIsActive) {
                if (!this.props.micIsMuted) muteMicDispatcherDispatch();
                if (!this.props.audioIsMuted) muteAudioDispatch();
                unpublishStreamAndRemoveFromRedux(this.props.dispatcherAudioStream, store);
            }
        } else {
            // Notify dispatcher that mic has been unmuted on activation of conferencing
            if (this.props.audioStreamIsActive && this.props.micIsMuted) {
                addNotificationAndShowDispatch('info.mic_unmtd', 'info', DISPLAY_ONLY_IN_SESSION);
            }
            activateConferencingDispatch();
            activateScreenSharingDispatcherDispatch();
            if (this.props.micIsMuted) unmuteMicDispatcherDispatch();
            if (this.props.audioIsMuted) unmuteAudioDispatch();
        }
    };

    toggleRecordingFeature = () => {
        const currentActiveState = this.props.streamRecordingIsActive;
        if (!currentActiveState) {
            activateStreamRecordingDispatch();
            if (!this.props.streamRecordingPermission) store.toggleStreamRecording(!currentActiveState);
            this._streamRecordingPermissionCheckInterval = setInterval(this.startRecordingIfPermissionGranted, 1000);
        } else {
            if (this.props.recordings.length >= STREAM_RECORDING_LIMIT) {
                this.setState({
                    recordingLimitIsReached: true,
                });
            }
            deactivateStreamRecordingDispatch();
            clearInterval(this._streamRecordingPermissionCheckInterval);
            this.stopRecordTimer();
        }
    };

    startRecordingIfPermissionGranted = () => {
        if (this.props.streamRecordingPermission) {
            this.startStreamRecording();
            clearInterval(this._streamRecordingPermissionCheckInterval);
        }
    };

    startStreamRecording = async () => {
        await store.startConversationRecording();
        this.startRecordTimer();
    };

    stopStreamRecording = async () => {
        await store.stopConversationRecording();
    };

    toggleBidi = async () => {
        const currentActiveState = this.props.bidiIsActive;
        this.setState({
            bidiLoading: true,
        });
        if (currentActiveState) {
            deactivateBidiDispatch();
            store.sendBidiIsDeactivated();
        } else {
            activateBidiDispatch();
            // initially activate background image if available
            if (!this.state.bidiHasBeenActivated && this.props.videoBackgroundImage.length !== 0) {
                activateBidiBackgroundDispatch();
            } else if (!this.state.bidiHasBeenActivated && this.props.videoBackgroundImage.length === 0) {
                activateBidiDispatch();
            }
            this.setState({
                bidiHasBeenActivated: true,
            });
        }
    };

    startRecordTimer = () => {
        this._streamRecordTimer = setInterval(this.timerChange, 1000);
    };

    timerChange = () => {
        this.setState({ streamRecordTimer: this.state.streamRecordTimer + 1 });
        if (this.state.streamRecordTimer >= 1800 || (!this.props.streamRecordingIsActive && !this.props.streamRecordingHasStarted)) {
            if (this.props.streamRecordingIsActive) this.toggleRecordingFeature();
            if (this.props.streamRecordingHasStarted) this.stopStreamRecording();
            this.stopRecordTimer();
        }
    };

    stopRecordTimer = () => {
        clearInterval(this._streamRecordTimer);
        this.setState({
            streamRecordTimer: 0,
        });
    };

    componentWillUnmount() {
        this.deactivateAllSwitches();
        clearTimeout(this._audioToggleTimeout);
        clearTimeout(this._bidiToggleTimeout);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.videoIsActive !== this.props.videoIsActive && this.props.videoIsActive) {
            enableSnapshotDispatch();
            enablePointerDispatch();
            enableDrawDispatch();
        } else if (prevProps.videoIsActive !== this.props.videoIsActive && this.props.videoIsActive === false) {
            disableSnapshotDispatch();
            disablePointerDispatch();
            disableDrawDispatch();
        }

        if (!this.props.videoIsActive && !this.props.audioStreamIsActive && this.props.streamRecordingIsActive && !this.props.streamRecordingHasStarted) {
            this.toggleRecordingFeature();
        } else if (!this.props.videoIsActive && !this.props.audioStreamIsActive && this.props.streamRecordingIsActive && this.props.streamRecordingHasStarted) {
            this.toggleRecordingFeature();
            this.stopStreamRecording();
        }
    }

    render() {
        const disabledNoConnection = !this.props.isConnected || this.props.connectionStatus === C_LOST;
        const recordingLimitIsReached = !this.props.streamRecordingIsActive && this.props.recordings.length >= STREAM_RECORDING_LIMIT;
        const noStreamToRecord = !this.props.videoIsActive && !this.props.audioStreamIsActive;

        const minutes = Math.floor(this.state.streamRecordTimer / 60);
        const seconds = this.state.streamRecordTimer % 60;

        return (
            <div className="webRTCSwitcher">
                <ConnectionOverlay force={true} />
                <ToggleSwitch
                    id="toggleLocation"
                    changeHandler={this.toggleGPS}
                    label={replaceText(this.props.texts, 'toggle.gps')}
                    isDisabled={this.props.gpsIsDisabled || disabledNoConnection || !this.props.callerPageLoaded}
                    isActive={this.props.gpsIsActive}
                />
                <ToggleSwitch
                    id="toggleVideo"
                    changeHandler={this.toggleVideo}
                    label={replaceText(this.props.texts, 'toggle.video')}
                    isDisabled={this.props.videoIsDisabled || disabledNoConnection || !this.props.callerPageLoaded}
                    isActive={this.props.videoIsActive}
                />
                {(this.props.snapshotFeature || this.props.hdSendFeature) && (
                    <ToggleSwitch
                        id="toggleSnapshot"
                        changeHandler={this.toggleSnapshot}
                        label={replaceText(this.props.texts, 'toggle.snapshot')}
                        isDisabled={this.props.snapshotIsDisabled || !this.props.hasVideoStream || disabledNoConnection}
                        isActive={this.props.snapshotIsActive}
                        sub={true}
                        title={this.props.drawIsActive ? replaceText(this.props.texts, 'toggle.disabled.by.draw') : ''}
                    />
                )}
                {this.props.pointerFeature && (
                    <ToggleSwitch
                        id="togglePointer"
                        changeHandler={this.togglePointer}
                        label={replaceText(this.props.texts, 'toggle.pointer')}
                        isDisabled={this.props.pointerIsDisabled || !this.props.hasVideoStream || disabledNoConnection}
                        isActive={this.props.pointerIsActive}
                        sub={true}
                        title={this.props.drawIsActive ? replaceText(this.props.texts, 'toggle.disabled.by.draw') : ''}
                    />
                )}
                {this.props.drawFeature && (
                    <ToggleSwitch
                        id="toggleDraw"
                        changeHandler={this.toggleDraw}
                        label={replaceText(this.props.texts, 'toggle.draw')}
                        isDisabled={this.props.drawIsDisabled || !this.props.hasVideoStream || disabledNoConnection}
                        isActive={this.props.drawIsActive}
                        sub={true}
                    />
                )}
                {this.props.chatFeature && (
                    <ToggleSwitch
                        id="toggleChat"
                        changeHandler={this.toggleChat}
                        label={replaceText(this.props.texts, 'toggle.chat')}
                        isActive={this.props.chatIsActive}
                        isDisabled={this.props.chatIsDisabled || disabledNoConnection || !this.props.callerPageLoaded}
                        title={this.props.drawIsActive ? replaceText(this.props.texts, 'toggle.disabled.by.draw') : ''}
                    />
                )}
                {this.props.audioStreamFeature && (
                    <ToggleSwitch
                        id="toggleAudio"
                        changeHandler={this.toggleAudioStream}
                        label={replaceText(this.props.texts, 'toggle.audio')}
                        isActive={this.props.audioStreamIsActive}
                        isDisabled={this.props.audioStreamIsDisabled || disabledNoConnection || !this.props.callerPageLoaded}
                        longDelay
                    />
                )}
                {this.props.bidiFeature && (
                    <ToggleSwitch
                        id="toggleBidi"
                        changeHandler={this.toggleBidi}
                        label={replaceText(this.props.texts, 'toggle.bidi')}
                        isActive={this.props.bidiIsActive}
                        isDisabled={this.props.bidiIsDisabled || disabledNoConnection || !this.props.callerPageLoaded}
                    />
                )}
                {this.props.sharingFeature && (
                    <ToggleSwitch
                        id="toggleConferencing"
                        changeHandler={this.toggleConferencing}
                        label={replaceText(this.props.texts, 'toggle.sharing')}
                        isActive={this.props.conferencingIsActive}
                        isDisabled={this.props.conferencingIsDisabled || !this.props.callerPageLoaded || disabledNoConnection}
                    />
                )}
                {this.props.streamRecordingFeature && (
                    <div className="toggle__container">
                        {this.props.streamRecordingIsActive && this.props.streamRecordingPermission && this.props.streamRecordingHasStarted && (
                            <div className="recording-timer">
                                <span>
                                    {minutes}:{seconds > 9 ? seconds : '0' + seconds}
                                </span>
                            </div>
                        )}
                        <ToggleSwitch
                            id="toggleRecordingFeature"
                            changeHandler={() => {
                                !recordingLimitIsReached && this.toggleRecordingFeature();
                            }}
                            label={replaceText(this.props.texts, 'toggle.recording')}
                            isActive={this.props.streamRecordingIsActive}
                            title={recordingLimitIsReached ? replaceText(this.props.texts, 'stream.recording.limit') : undefined}
                            isDisabled={
                                !this.props.callerPageLoaded ||
                                disabledNoConnection ||
                                this.state.streamRecordingLoading ||
                                recordingLimitIsReached ||
                                noStreamToRecord
                            }
                            longDelay
                        />
                    </div>
                )}
            </div>
        );
    }
}

// PropTypes for this Component
WebRTCSwitcher.propTypes = {
    videoIsDisabled: PropTypes.bool,
    gpsIsDisabled: PropTypes.bool,
    snapshotIsDisabled: PropTypes.bool,
    videoIsActive: PropTypes.bool,
    gpsIsActive: PropTypes.bool,
    snapshotIsActive: PropTypes.bool,
    chatIsActive: PropTypes.bool,
    pointerIsDisabled: PropTypes.bool,
    pointerIsActive: PropTypes.bool,
    pointerFeature: PropTypes.bool,
    snapshotFeature: PropTypes.bool,
    chatFeature: PropTypes.bool,
    chatIsDisabled: PropTypes.bool,
    hdSendFeature: PropTypes.bool,
    hdSendIsActive: PropTypes.bool,
    drawFeature: PropTypes.bool,
    drawIsActive: PropTypes.bool,
    drawIsDisabled: PropTypes.bool,
    connectionStatus: PropTypes.string,
    hasVideoStream: PropTypes.bool,
    isConnected: PropTypes.bool,
    wasChatActiveBefore: PropTypes.bool,
    photoPermission: PropTypes.bool,
    isPaintingAllowed: PropTypes.bool,
    texts: PropTypes.any,
    sharingFeature: PropTypes.bool,
    conferencingIsDisabled: PropTypes.bool,
    conferencingIsActive: PropTypes.bool,
    audioStreamFeature: PropTypes.bool,
    audioStreamIsDisabled: PropTypes.bool,
    audioStreamIsActive: PropTypes.bool,
    micIsMuted: PropTypes.bool,
    callerPageLoaded: PropTypes.bool,
    dispatcherAudioStream: PropTypes.object,
    bidiFeature: PropTypes.bool,
    bidiIsDisabled: PropTypes.bool,
    bidiIsActive: PropTypes.bool,
    dispatcherBidiStream: PropTypes.object,
    videoStreamPermission: PropTypes.bool,
    screenSharingIsActive: PropTypes.bool,
    audioIsMuted: PropTypes.bool,
    snapshotDisclaimerAccepted: PropTypes.bool,
    audioStreamPermission: PropTypes.bool,
    streamRecordingIsActive: PropTypes.bool,
    streamRecordingPermission: PropTypes.bool,
    recordings: PropTypes.array,
    streamRecordingFeature: PropTypes.bool,
    streamRecordingHasStarted: PropTypes.bool,
    videoBackgroundImage: PropTypes.any,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        videoIsDisabled: state.application.videoIsDisabled,
        gpsIsDisabled: state.application.gpsIsDisabled,
        snapshotIsDisabled: state.application.snapshotIsDisabled,
        pointerIsDisabled: state.application.pointerIsDisabled,
        videoIsActive: state.application.videoIsActive,
        gpsIsActive: state.application.gpsIsActive,
        snapshotIsActive: state.application.snapshotIsActive,
        chatIsActive: state.application.chatIsActive,
        pointerIsActive: state.application.pointerIsActive,
        pointerFeature: state.features.pointerFeature,
        snapshotFeature: state.features.snapshotFeature,
        hdSendFeature: state.features.hdSendFeature,
        hdSendIsActive: state.application.hdSendIsActive,
        drawFeature: state.features.drawFeature,
        drawIsActive: state.application.drawIsActive,
        drawIsDisabled: state.application.drawIsDisabled,
        chatFeature: state.features.chatFeature,
        chatIsDisabled: state.application.chatIsDisabled,
        connectionStatus: state.connection.status,
        hasVideoStream: state.application.hasVideoStream,
        wasChatActiveBefore: state.application.wasChatActiveBefore,
        isConnected: state.connection.isConnected,
        photoPermission: state.session.photoPermission,
        isPaintingAllowed: state.paint.isPaintingAllowed,
        texts: state.texts.texts,
        sharingFeature: state.features.sharingFeature,
        conferencingIsDisabled: state.application.conferencingIsDisabled,
        conferencingIsActive: state.application.conferencingIsActive,
        audioStreamFeature: state.features.audioStreamFeature,
        audioStreamIsDisabled: state.application.audioStreamIsDisabled,
        audioStreamIsActive: state.application.audioStreamIsActive,
        micIsMuted: state.conferencing.micIsMuted,
        callerPageLoaded: state.application.callerPageLoaded,
        dispatcherAudioStream: state.streams.dispatcherAudioStream,
        bidiFeature: state.features.bidiFeature,
        bidiIsDisabled: state.application.bidiIsDisabled,
        bidiIsActive: state.application.bidiIsActive,
        dispatcherBidiStream: state.streams.dispatcherBidiStream,
        videoStreamPermission: state.permissions.videoStreamPermission,
        screenSharingIsActive: state.conferencing.screenSharingIsActive,
        audioIsMuted: state.application.audioIsMuted,
        snapshotDisclaimerAccepted: state.disclaimers.snapshotDisclaimerAccepted,
        audioStreamPermission: state.permissions.audioStreamPermission,
        streamRecordingFeature: state.features.streamRecordingFeature,
        streamRecordingIsActive: state.application.streamRecordingIsActive,
        streamRecordingPermission: state.session.streamRecordingPermission,
        recordings: state.session.recordings,
        streamRecordingHasStarted: state.application.streamRecordingHasStarted,
        videoBackgroundImage: state.session.videoBackgroundImage,
    };
};

export default connect(mapStateToProps)(WebRTCSwitcher);
