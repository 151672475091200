import React from 'react';
import PropTypes from 'prop-types';
import './ModalOverlay.scss';
import { replaceText } from '../../helper/helper';
import { connect } from 'react-redux';

const ModalOverlay = ({ modalIsVisible, isPartialModal, hasCloseButton, closeHandler, children, texts, isCaller }) => {
    let classes = `modalOverlay ${modalIsVisible && 'modalOverlay--isVisible'} ${isPartialModal && 'modalOverlay--isPartialModal'}`;

    return (
        <div className={classes}>
            <div className={isCaller ? 'modalOverlay__content--isCaller' : 'modalOverlay__content'}>
                <div className="modalOverlay__close" onClick={closeHandler}>
                    {hasCloseButton && replaceText(texts, 'modal.close')}
                </div>
                <span>{children}</span>
            </div>
        </div>
    );
};

// PropTypes for this Component
ModalOverlay.propTypes = {
    modalIsVisible: PropTypes.bool,
    closeHandler: PropTypes.func,
    children: PropTypes.any,
    texts: PropTypes.any,
    isPartialModal: PropTypes.bool,
    hasCloseButton: PropTypes.bool,
    isCaller: PropTypes.bool,
};

const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
    };
};

export default connect(mapStateToProps)(ModalOverlay);
