import { useEffect } from "react";
import { useSelector } from "react-redux";
import { UserType } from "../types";
import { callerStore } from "../store/CallerStore";

export function useHandleDisclaimerState(user: UserType) {
    // currently we only use this on the caller side for delaying the caller details until the disclaimer check is passed
    const disclaimerFeatureCaller = useSelector((state: any) => state.features.disclaimerFeatureCaller);
    // const disclaimerFeatureDispatcher = useSelector((state: any) => state.features.disclaimerFeatureDispatcher);
    const usageDisclaimerStateCaller = useSelector((state: any) => state.disclaimers.usageDisclaimerStateCaller);
    // const usageDisclaimerStateDispatcher = useSelector((state: any) => state.disclaimers.usageDisclaimerStateDispatcher);
    // const usageDisclaimerStateConference = useSelector((state: any) => state.disclaimers.usageDisclaimerStateConference);

    useEffect(() => {
        if (user === 'caller') {
            if (usageDisclaimerStateCaller === 'accepted' || usageDisclaimerStateCaller === 'none') {
                callerStore.sendCallerDetails();
            }
        }
    }, [disclaimerFeatureCaller, usageDisclaimerStateCaller, user])
}