import React from 'react';

function NoVideo() {
    return (
        // <svg viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg">
        //     <g fill="#e6e7e8">
        //         <path d="M78.433 30.444a3.694 3.694 0 013.692 3.692v11.813a3.69 3.69 0 001.871 3.212 3.703 3.703 0 003.721-.05l20.241-12.146v46.017L87.717 70.836c-.521-.312-1.109-.45-1.7-.483l20.179 20.179 3.558 2.133a3.684 3.684 0 003.717.046 3.685 3.685 0 001.875-3.208V30.444a3.685 3.685 0 00-1.875-3.208 3.684 3.684 0 00-3.717.046l-20.25 12.146v-5.292c0-6.104-4.967-11.071-11.071-11.071H38.729l7.379 7.379zM78.433 89.503H11.996a3.692 3.692 0 01-3.688-3.692V34.136a3.696 3.696 0 013.688-3.692h13.237l56.634 56.638c-.521 1.404-1.846 2.421-3.434 2.421zm40.6 24.308L6.146.919.925 6.136 17.85 23.065h-5.854C5.892 23.065.925 28.032.925 34.136v51.675c0 6.104 4.967 11.071 11.071 11.071h66.437c3.609 0 6.779-1.758 8.796-4.442l26.583 26.588z" />
        //     </g>
        // </svg>

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.009 141.314">
            <g
                fill="none"
                stroke="#e6e7e8"
                strokeLinecap="round"
                strokeWidth="8">
                <path
                    strokeLinejoin="round"
                    d="m140.009 38.236-21.475 21.475V45.394A10.737 10.737 0 0 0 107.8 34.657H14.738A10.737 10.737 0 0 0 4 45.395v50.108a10.737 10.737 0 0 0 10.738 10.737H107.8a10.737 10.737 0 0 0 10.738-10.738V81.186l21.471 21.471Z"
                />
                <path d="M137 135.657 7 5.657" />
            </g>
        </svg>
    );
}

export default NoVideo;
