import React from 'react';

export default function KickUser() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14">
            <path
                data-name="Path 3086"
                d="M7 0a7 7 0 1 0 7 7 6.994 6.994 0 0 0-7-7Zm3.01 10.01a.7.7 0 0 1-.987 0L7 7.987 4.977 10.01a.7.7 0 0 1-.987-.987L6.013 7 3.99 4.977a.7.7 0 0 1 .987-.987L7 6.013 9.023 3.99a.7.7 0 0 1 .987.987L7.987 7l2.023 2.023a.71.71 0 0 1 0 .987Z"
                fill="#fff"
            />
        </svg>
    );
}
