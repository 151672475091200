import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './InvitationPanel.scss';
import { replaceText } from '../../helper/helper';
import { connect } from 'react-redux';
import ModalOverlay from '../Globals/ModalOverlay';
import Invitation from './Invitation';

const InvitationPanel = ({ texts, conferencingIsActive, conferenceUserStreams }) => {
    const [panelIsOpen, setPanelIsOpen] = useState(false);

    const openInvitationPanel = () => {
        setPanelIsOpen(true);
    };

    const closeInvitationPanel = () => {
        setPanelIsOpen(false);
    };

    return (
        <div className="invitationPanel">
            <div className="invitationPanel__container">
                <span>{replaceText(texts, 'invitation.info')}</span>
                {conferenceUserStreams.length === 0 ? <span>-----</span> : <span>{conferenceUserStreams.length}</span>}
            </div>
            <button className="btn btn--primary" disabled={conferencingIsActive ? false : true} onClick={openInvitationPanel}>
                {replaceText(texts, 'invitation.button')}
            </button>
            <ModalOverlay modalIsVisible={panelIsOpen} hasCloseButton closeHandler={closeInvitationPanel}>
                <Invitation closePanelHandler={closeInvitationPanel} />
            </ModalOverlay>
        </div>
    );
};

// PropTypes for this Component
InvitationPanel.propTypes = {
    texts: PropTypes.any,
    conferencingIsActive: PropTypes.bool,
    conferenceUserStreams: PropTypes.any,
};

const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
        conferencingIsActive: state.application.conferencingIsActive,
        conferenceUserStreams: state.streams.conferenceUserStreams,
    };
};

export default connect(mapStateToProps)(InvitationPanel);
