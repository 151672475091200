import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { copyLink, replaceText } from '../../helper/helper';

import './CopyAddressButton.scss';

export const CopyAddressButtonComponent = ({ texts, userType }) => {
    const [linkCopiedSuccessfully, setLinkCopiedSuccessfully] = useState(false);

    let classes = `btn btn--primary ${linkCopiedSuccessfully ? 'btn--isHidden' : ''}`;

    return (
        <div>
            <button
                className={classes}
                onClick={() => {
                    const copyWasSuccessful = copyLink(userType);
                    if (copyWasSuccessful) {
                        setLinkCopiedSuccessfully(true);
                        setTimeout(() => {
                            setLinkCopiedSuccessfully(false);
                        }, 2000);
                    }
                }}>
                {replaceText(texts, 'sms.linkcopy.button')}
            </button>
            <p className={`copy-link alert alert--success ${linkCopiedSuccessfully ? '' : 'copy-link--isHidden'}`}>{replaceText(texts, 'sms.linkcopy.info')}</p>
        </div>
    );
};

// PropTypes for this Component
CopyAddressButtonComponent.propTypes = {
    texts: PropTypes.object,
    userType: PropTypes.string,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
    };
};

// Connect Props and Dispatch to Component
export const CopyAddressButton = connect(mapStateToProps)(CopyAddressButtonComponent);
