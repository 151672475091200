import { errorLog } from "../helper/logging";
import { callerStore } from "../store/CallerStore";
import { conferenceStore } from "../store/ConferenceStore";
import { oauthStore } from "../store/OAuthStore";
import { HTTP_ERRORS, getData, postData } from "./common";

const dispatcherDisclaimerEndpoint = process.env.REACT_APP_DISCLAIMER_DISPATCHER_ENDPOINT;
const callerDisclaimerEndpoint = process.env.REACT_APP_DISCLAIMER_CALLER_ENDPOINT;
const dispatcherDisclaimerAcceptEndpoint = process.env.REACT_APP_DISCLAIMER_DISPATCHER_ACCEPT_ENDPOINT;

export const getDisclaimerForDispatcher = async () => {
    const results = await getData(dispatcherDisclaimerEndpoint, oauthStore.authToken).catch(e => {
        if (e.message !== HTTP_ERRORS.HTTP_406) {
            errorLog({
                message: 'getDisclaimerForDispatcher() error getting data from backend. - code: GT DCLMR',
                error: e,
                eventId: 'DISCLAIMER_DISPATCHER',
            });
        }

    });
    return results
}

export const getDisclaimerForCaller = async () => {
    const token = callerStore.authToken;
    const results = await getData(callerDisclaimerEndpoint, token).catch(e => {
        if (e.message !== HTTP_ERRORS.HTTP_406) {
            errorLog({
                message: 'getDisclaimerForCaller() error getting data from backend. - code: GT DCLMR',
                error: e,
                eventId: 'DISCLAIMER_CALLER',
            });
        }
    });
    return results
}

export const getDisclaimerForConference = async () => {
    const token = conferenceStore.authToken;
    const results = await getData(callerDisclaimerEndpoint, token).catch(e => {
        if (e.message !== HTTP_ERRORS.HTTP_406) {
            errorLog({
                message: 'getDisclaimerForCaller() error getting data from backend. - code: GT DCLMR',
                error: e,
                eventId: 'DISCLAIMER_CONFERENCE',
            });
        }
    });
    return results
}

export const acceptDisclaimerForDispatcher = async () => {
    return await postData(dispatcherDisclaimerAcceptEndpoint, {}, oauthStore.authToken).catch(e => {
        if (e.message !== HTTP_ERRORS.HTTP_406) {
            errorLog({
                message: 'acceptDisclaimerForDispatcher() error. - code: ACCPT DCLMR',
                error: e,
                eventId: 'ACCEPT_DISCLAIMER_DISPATCHER',
            });
        }
    });
}

