import React, { useEffect, useRef, useState } from 'react';
import { replaceText } from '../../helper/helper';
import { connect, ConnectedProps } from 'react-redux';
import './NotesPanel.scss';
import { activateNotesDispatch, deactivateNotesDispatch } from '../../redux/actions/application';
import { dispatchAddSessionNotes } from '../../redux/actions/session';

interface NotesPanelProps extends ConnectedProps<typeof connector> {
  texts: string;
  notesIsActive: boolean;
  notes: string;
  callerPageLoaded: boolean;
}

const NotesPanel: React.FC<NotesPanelProps> = ({ texts, notesIsActive, notes, callerPageLoaded }) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const inputTimeout = useRef<ReturnType<typeof setTimeout>>();

  const [input, setNotes] = useState('');

  const updateNotes = () => {
    const notesInputValue = inputRef.current!.value;

    if (isNotesValid(notesInputValue)) {
      setNotes(notesInputValue);
    }
  };

  const updateInput = () => {
    setNotes(inputRef.current!.value);
  };

  const isNotesValid = (input: string) => {
    return input.length >= 0 && input.length > 5000;
  };

  useEffect(() => {
    if (!notesIsActive && input.length > 0) {
      activateNotesDispatch();
    } else if (notesIsActive && input.length === 0) {
      deactivateNotesDispatch();
    }
  }, [notesIsActive, input]);

  useEffect(() => {
    if (notesIsActive && notes !== input) {
      clearTimeout(inputTimeout.current!);
      inputTimeout.current = setTimeout(() => {
        dispatchAddSessionNotes(input);
      }, 1000);
    }
  }, [notesIsActive, notes, input]);

  return (
    <div className="notes-panel">
      <textarea
        id="input"
        ref={inputRef}
        value={input}
        disabled={!callerPageLoaded}
        onChange={updateInput}
        placeholder={replaceText(texts, 'notes.placeholder')}
        onKeyUp={updateNotes}
        maxLength={5000}></textarea>
      <div className="notes-panel__hint-seperator">
        <span>{5000 - input.length} / 5000</span>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    texts: state.texts.texts,
    notesIsActive: state.application.notesIsActive,
    notes: state.session.notes,
    callerPageLoaded: state.application.callerPageLoaded,
  };
};

const connector = connect(mapStateToProps);
export default connector(NotesPanel);
