import React, {
    Children,
    isValidElement,
    cloneElement,
    useState,
    useEffect,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

/**
 * GridBox
 * Container for multiple components.
 * Allows the container to open and close.
 */
const GridBox = ({
    alwaysOpened,
    openOnFeatureActivation,
    isClosed,
    children,
    addClass,
    isContainer,
    headline,
    toggleHandler = () => {},
}) => {
    const [gridboxIsClosed, setGridboxIsClosed] = useState(isClosed);
    const [openedWhileFeatureActivated, setOpenedWhileFeatureActivated] =
        useState(false);

    const classes = clsx(addClass, {
        gridContainer: isContainer,
        'gridContainer--closed': gridboxIsClosed,
        'gridContainer--alwaysOpened': alwaysOpened,
    });

    const childrenWithProps = Children.map(children, child => {
        if (isValidElement(child)) {
            return cloneElement(child, { boxIsClosed: gridboxIsClosed });
        }

        return child;
    });

    const toggle = useCallback(() => {
        if (!alwaysOpened) {
            setGridboxIsClosed(!gridboxIsClosed);
            toggleHandler(gridboxIsClosed);
        }
    }, [gridboxIsClosed, toggleHandler, alwaysOpened]);

    useEffect(() => {
        const openGridboxIfClosed = () => {
            if (openOnFeatureActivation && !openedWhileFeatureActivated) {
                if (gridboxIsClosed) {
                    toggle();
                }
                setOpenedWhileFeatureActivated(true);
            } else if (
                !openOnFeatureActivation &&
                openedWhileFeatureActivated
            ) {
                setOpenedWhileFeatureActivated(false);
            }
        };

        const keepGridBoxOpened = () => {
            if (alwaysOpened && gridboxIsClosed) {
                setGridboxIsClosed(false);
            }
        };

        if (openOnFeatureActivation !== undefined) {
            openGridboxIfClosed();
        }

        if (alwaysOpened !== undefined) {
            keepGridBoxOpened();
        }
    }, [
        openOnFeatureActivation,
        gridboxIsClosed,
        openedWhileFeatureActivated,
        toggle,
        alwaysOpened,
    ]);

    return (
        <div className={classes}>
            {isContainer && headline ? (
                <header onClick={toggle}>{headline.toUpperCase()}</header>
            ) : (
                ''
            )}
            <main>{childrenWithProps}</main>
        </div>
    );
};

// PropTypes for this Component
GridBox.propTypes = {
    toggleHandler: PropTypes.func,
    headline: PropTypes.string,
    isContainer: PropTypes.bool,
    addClass: PropTypes.string,
    children: PropTypes.any,
    isClosed: PropTypes.bool,
    openOnFeatureActivation: PropTypes.bool,
    alwaysOpened: PropTypes.bool,
};

export default GridBox;
