import logoLink from '../resources/emex.svg';
import companyLogoLink from '../resources/corevas-logo.svg';
//import footerLogoLink from '../resources/nobrand.svg';

export const cssVariables = {
    primary_light: 'rgb(153, 203, 195)',
    primary: 'rgb(0, 168, 165)',
    text_on_primary: 'white',
    text_on_primary_caller: 'white',

    secondary: '#D25425',
    text_on_secondary: 'white',

    font: '"TT Rounds Condensed", sans-serif',
    link_color: 'rgb(0, 131, 177)',
    font_color: '#000',
    font_weight: '700',

    border_radius: '20px',
    border_width: '2px',
    border_style: 'solid',
    border_radius_narrow: '10px',
    border_radius_super_narrow: '5px',
    border_width_nav: '8px',

    disabled: '#dbdbdb',

    gridbox_header_bg: 'rgb(120, 120, 120)',
    gridbox_header_text: '#ffffff',
    gridbox_padding: '8px',
    gridbox_header_padding: '8px',

    red: 'red',
    green: 'green',
    connecting: '#c0c0c0',
    connected: '#07A7A5',
    aborted: '#D25425',
    paused: '#F5CD41',

    header_footer_seperator: 'rgb(153, 203, 195)',
    panel_highlight: 'rgb(0, 168, 165)',
    chat_background_right: '#C2E0DB',
    chat_background_left: '#dbdbdb',

    active: 'rgb(7, 167, 165)',
    inactive: '#D25425',
    text_on_active: '#000',
    text_on_inactive: '#000',

    dropdown_background: '#99CBC3',

    phonebook_dropdown: '#99CBC3',
    phonebook_dropdown_svg: '#000',

    pointer: '#D25425',

    toggle_switch: 'rgb(245, 245, 245)',

    external_icon_fill: 'rgb(0, 168, 165)',
};

export const logo = logoLink;
export const companyTitle = 'Corevas';
export const companyLogo = companyLogoLink;
export const footerLogo = 'footerLogoLink';
