import { DEBUG, DISPLAY_ONLY_IN_SESSION } from '../config';
import {
    activateScreenSharingDispatch,
    deactivateScreenSharingDispatcherDispatch,
    dispatchAddStreamInfo,
    dispatchRemoveStreamInfo,
} from '../redux/actions/conferencing';
import { connectionEstablishedDispatch, connectionLostDispatch } from '../redux/actions/connection';
import { addLogDispatch } from '../redux/actions/logs';
import { dispatchAddSessionRecording } from '../redux/actions/session';
import {
    dispatchAddCallerAudioStream,
    dispatchAddCallerStream,
    dispatchAddConferenceUserStream,
    dispatchAddDispatcherAudioStream,
    dispatchAddDispatcherBidiStream,
    dispatchAddDispatcherStream,
    dispatchRemoveCallerAudioStream,
    dispatchRemoveConferenceUserStream,
    dispatchRemoveDispatcherAudioStream,
    dispatchRemoveDispatcherBidiStream,
    dispatchRemoveDispatcherStream,
} from '../redux/actions/stream';
import { store as dispatcherStore } from '../store/DispatcherStore';
import { conferenceStore } from '../store/ConferenceStore';
import { addCallerVideoStreamInDiv } from './streamHandling';
import { callerStore } from '../store/CallerStore';
import reduxStore from '../redux/store';
import { addNotificationAndShowDispatch } from '../redux/actions/notifications';
import { activateBidiCallerDispatch, deactivateAudioStreamDispatcherDispatch, deactivateBidiCallerDispatch, dispatchStartStreamRecording, dispatchStopStreamRecording } from '../redux/actions/application';
import { createKpiLog } from './helper';
import { postNewRecording } from '../api/backendApi';

// get URL address and abstract a session id, caller id, and a conversation name
export const getURLParams = () => {
    var parts = window.location.href.split('/');
    var lastSegment = parts.pop() || parts.pop(); // handle potential trailing slash
    var preLastSegment = parts.pop() || parts.pop(); // handle potential trailing slash

    return {
        sessionId: lastSegment,
        callerId: preLastSegment,
        conversationName: lastSegment + '' + preLastSegment,
    };
};

// Conference user display name
export const createUserDisplayName = (store, userName) => {
    store.connectedSession.setUsername(userName);
};

export const enterConversation = (store, options = {}) => {
    const { conversationName } = getURLParams();
    store.connectedConversation = store.connectedSession.getOrCreateConversation(conversationName, options);
};

// Dispatcher screen share stream event handler
export const dispatcherStreamHandlers = dispatcherStream => {
    dispatcherStream.on('trackStopped', event => {
        // Used to detect when user stop the screenSharing with Chrome/Firefox
        dispatcherStore.stoppedViaBrowserButton = true;
        deactivateScreenSharingDispatcherDispatch();
        dispatchRemoveDispatcherStream();
        dispatcherStore.sendScreenSharingToggled(false);
    });
};

export const unpublishStreamAndRemoveFromRedux = (stream, store) => {
    if (stream) {
        store.connectedConversation.unpublish(stream);
        stream.release();
        dispatchRemoveDispatcherAudioStream();
    }
};

// handlers for the dispatcher conversation
export const loadEventListenersDispatcher = () => {
    dispatcherStore.connectedConversation
        .on('streamAdded', handleStreamAddedDispatcher)
        .on('streamRemoved', handleStreamRemovedDispatcher)
        .on('streamListChanged', handleStreamChangedDispatcher)
        .on('contactJoined', handleContactJoinedDispatcher)
        .on('contactLeft', handleContactLeftDispatcher)
        .on('recordingStarted', function (recordingInfo) {
            let status = 'started';
            dispatchAddSessionRecording({ recordingInfo, status });
            postNewRecording(recordingInfo.mediaId, recordingInfo.mediaURL, recordingInfo.recordedFileName);
        })
        .on('recordingStopped', recordingInfo => {
            let status = 'stopped';
            dispatchAddSessionRecording({ recordingInfo, status });
        })
        .on('recordingAvailable', recordingInfo => {
            dispatchAddSessionRecording({ recordingInfo });
            fetchVideoAndThumbnailAndDispatch(recordingInfo);
        });
};

export const fetchVideoAndThumbnailAndDispatch = recordingInfo => {
    fetch(recordingInfo.mediaURL)
        .then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            getThumbnailURL(url);
        });

    // Function to get the thumbnail URL
    function getThumbnailURL(videoURL) {
        const video = document.createElement('video');
        video.src = videoURL;
        video.currentTime = 1;

        const canvas = document.createElement('canvas');

        const ctx = canvas.getContext('2d');
        video.addEventListener('loadedmetadata', function () {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            video.addEventListener('canplaythrough', function () {
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                setTimeout(function () {
                    const image = canvas.toDataURL();
                    let status = 'available';
                    dispatchAddSessionRecording({ recordingInfo, image, status });
                    return image;
                }, 100); // Delay by 100ms
            });
        });
    }
};

export const unloadEventListenersDispatcher = () => {
    dispatcherStore.connectedConversation
        .removeListener('streamListChanged', handleStreamChangedDispatcher)
        .removeListener('streamRemoved', handleStreamRemovedDispatcher)
        .removeListener('contactJoined', handleContactJoinedDispatcher)
        .removeListener('contactLeft', handleContactLeftDispatcher)
        .removeListener('streamAdded', handleStreamAddedDispatcher);
};

// handlers for the caller conversation
export const loadEventListenersCaller = () => {
    callerStore.connectedConversation
        .on('streamAdded', handleStreamAddedCaller)
        .on('streamRemoved', handleStreamRemovedCaller)
        .on('streamListChanged', handleStreamChangedCaller)
        .on('contactLeft', handleContactLeftCaller)
        .on('recordingStarted', recordingInfo => {
            dispatchStartStreamRecording();
        })
        .on('recordingStopped', recordingInfo => {
            dispatchStopStreamRecording();
        })
};

export const unloadEventListenersCaller = () => {
    callerStore.connectedConversation
        .removeListener('streamAdded', handleStreamAddedCaller)
        .removeListener('streamRemoved', handleStreamRemovedCaller)
        .removeListener('streamListChanged', handleStreamChangedCaller)
        .removeListener('contactLeft', handleContactLeftCaller)
        .removeListener('recordingStarted')
        .removeListener('recordingStopped');
    };

// handlers for the conference user conversation
export const loadEventListenersConferenceUser = () => {
    conferenceStore.connectedConversation
        .on('streamAdded', handleStreamAddedConferenceUser)
        .on('streamRemoved', handleStreamRemovedConferenceUser)
        .on('streamListChanged', handleStreamChangedConferenceUser)
        .on('contactJoined', handleContactJoinedConferenceUser)
        .on('contactLeft', handleContactLeftConferenceUser);
};

export const unloadEventListenersConferenceUser = () => {
    if (conferenceStore.connectedConversation !== null) {
        conferenceStore.connectedConversation
            .removeListener('streamListChanged', handleStreamChangedConferenceUser)
            .removeListener('streamRemoved', handleStreamRemovedConferenceUser)
            .removeListener('contactJoined', handleContactJoinedConferenceUser)
            .removeListener('contactLeft', handleContactLeftConferenceUser)
            .removeListener('streamAdded', handleStreamAddedConferenceUser);
    }
};

// HANDLE STREAM ADDED TO CONVERSATION

const handleStreamAddedDispatcher = stream => {
    addStream(stream, dispatcherStore);
};

const handleStreamAddedCaller = stream => {
    addStream(stream, callerStore);
};

const handleStreamAddedConferenceUser = stream => {
    addStream(stream, conferenceStore);
};

const addStream = (stream, store) => {
    const { callerId } = getURLParams();

    // DISPATCHER
    if (store.type === 'dispatcher') {
        if (stream.contact.userData.username === callerId) {
            // incoming caller stream
            if (stream.type === 'audio') {
                dispatchAddCallerAudioStream(stream);
                handleIncomingAudio(stream, store);
            }
            if (stream.type === 'video') {
                dispatcherStore.callerStream = stream;
                dispatchAddCallerStream(stream);
                addCallerVideoStreamInDiv(stream, true);
            }
        } else {
            // incoming conference user stream
            if (reduxStore.getState().application.conferencingIsActive) {
                const additionalStates = {
                    0: stream.contact.userData.username,
                };

                createKpiLog('infoConferenceUser', 'joined', additionalStates);

                dispatchAddConferenceUserStream(stream);
                handleIncomingAudio(stream, store);
            }
        }
    }

    // CALLER
    if (store.type === 'caller') {
        if (stream.contact.userData.username === 'dispatcher') {
            // incoming dispatcher stream
            if (stream.type === 'audio') {
                dispatchAddDispatcherAudioStream(stream);
                handleIncomingAudio(stream, store);
            }
            if (stream.type === 'video') {
                reduxStore.getState().conferencing.streamInfo.forEach(streamInfo => {
                    if (stream.streamId === streamInfo.streamId) {
                        // dispatcher only publishes video for screen sharing and bidi
                        // added !streamInfo.hasAudio to prevent subscribing to dispatcherDummyStream
                        if (!streamInfo.isScreensharing) {
                            if (streamInfo.context.bidi) {
                                let bidiContainer = document.getElementById('bidiContainerCaller__inner');
                                bidiContainer.innerHTML = '';
                                dispatchAddDispatcherBidiStream(stream);
                                addBidiStreamToCallerContainer(stream);
                                activateBidiCallerDispatch();
                            }
                        }
                    }
                });
            }
        } else {
            // incoming conference user audio stream
            dispatchAddConferenceUserStream(stream);
            handleIncomingAudio(stream, store);
        }
    }

    // CONFERENCE USER
    if (store.type === 'conference') {
        if (stream.contact.userData.username === callerId) {
            // incoming caller audio stream
            if (stream.type === 'audio') {
                dispatchAddCallerAudioStream(stream);
                handleIncomingAudio(stream, store);
            }
        } else if (stream.contact.userData.username === 'dispatcher') {
            // incoming dispatcher audio stream
            if (stream.type === 'audio') {
                dispatchAddDispatcherAudioStream(stream);
                handleIncomingAudio(stream, store);
            }
            // incoming dispatcher screen share stream
            if (stream.type === 'video') {
                reduxStore.getState().conferencing.streamInfo.forEach(streamInfo => {
                    if (stream.streamId === streamInfo.streamId) {
                        if (streamInfo.isScreensharing) {
                            dispatchAddDispatcherStream(stream);
                            addStreamToConferenceUserContainer(stream);
                            activateScreenSharingDispatch();
                        }
                    }
                });
            }
        } else {
            // incoming conference user audio stream
            dispatchAddConferenceUserStream(stream);
            handleIncomingAudio(stream, store);
        }
    }
};

const handleIncomingAudio = (stream, store) => {
    // DISPATCHER
    if (store.type === 'dispatcher') {
        if (!reduxStore.getState().application.audioIsMuted) {
            readdAllStreamsToContainerDispatcher();
        } else {
            if (reduxStore.getState().application.conferencingIsActive) addNotificationAndShowDispatch('info.cusr_jnd_mtd', 'info', DISPLAY_ONLY_IN_SESSION);
        }
    }

    // CALLER
    if (store.type === 'caller') {
        if (!reduxStore.getState().application.audioIsMuted) {
            readdAllStreamsToContainerCaller();
        }
    }

    // CONFERENCE USER
    if (store.type === 'conference') {
        addStreamToConferenceUserContainer(stream);
    }
};

// HANDLE STREAM REMOVED TO CONVERSATION

const handleStreamRemovedDispatcher = stream => {
    removeStream(stream, dispatcherStore);
};

const handleStreamRemovedCaller = stream => {
    removeStream(stream, callerStore);
};

const handleStreamRemovedConferenceUser = stream => {
    removeStream(stream, conferenceStore);
};

const removeStream = (stream, store) => {
    const { callerId } = getURLParams();

    if (store.type === 'dispatcher') {
        if (stream.contact.userData.username === callerId) {
            // caller stream
            if (stream.type === 'audio') {
                dispatchRemoveCallerAudioStream();
                handleRemovalOfIncomingStream(stream, store);
            }
        } else {
            const additionalStates = {
                0: stream.contact.userData.username,
            };

            createKpiLog('infoConferenceUser', 'left', additionalStates);

            dispatchRemoveConferenceUserStream(stream.streamId);
            handleRemovalOfIncomingStream(stream, store);
        }
    }

    if (store.type === 'caller') {
        if (stream.contact.userData.username === 'dispatcher') {
            if (stream.type === 'video') {
                reduxStore.getState().conferencing.streamInfo.forEach(streamInfo => {
                    if (stream.streamId === streamInfo.streamId) {
                        if (!streamInfo.isScreensharing) {
                            handleRemovalOfIncomingStream(stream, store);
                            dispatchRemoveDispatcherBidiStream();
                            if (reduxStore.getState().application.bidiIsActive) {
                                deactivateBidiCallerDispatch();
                            }
                        }
                    }
                });
            }
        }

        if (reduxStore.getState().streams.dispatcherAudioStream !== null) {
            if (stream.type !== 'video') {
                if (stream.streamId === reduxStore.getState().streams.dispatcherAudioStream.streamId) {
                    // dispatcher audio stream
                    dispatchRemoveDispatcherAudioStream();
                    handleRemovalOfIncomingStream(stream, store);
                } else {
                    // conference user stream
                    dispatchRemoveConferenceUserStream(stream.streamId);
                    handleRemovalOfIncomingStream(stream, store);
                }
            }
        }
    }

    if (store.type === 'conference') {
        if (reduxStore.getState().streams.dispatcherAudioStream) {
            if (stream.streamId === reduxStore.getState().streams.dispatcherAudioStream.streamId) {
                // dispatcher audio stream
                dispatchRemoveDispatcherAudioStream();
                handleRemovalOfIncomingStream(stream, store);
            }
        }

        if (reduxStore.getState().streams.dispatcherStream) {
            if (stream.streamId === reduxStore.getState().streams.dispatcherStream.streamId) {
                // dispatcher screen share stream
                dispatchRemoveDispatcherStream();
                handleRemovalOfIncomingStream(stream, store);
            }
        }

        if (reduxStore.getState().streams.conferenceUserStreams.length !== 0) {
            reduxStore.getState().streams.conferenceUserStreams.forEach(reduxStream => {
                if (stream.streamId === reduxStream.streamId) {
                    // conference user stream
                    dispatchRemoveConferenceUserStream(stream.streamId);
                    handleRemovalOfIncomingStream(stream, store);
                }
            });
        }
    }
};

const handleRemovalOfIncomingStream = (stream, store) => {
    const { callerId } = getURLParams();

    // DISPATCHER
    if (store.type === 'dispatcher') {
        if (stream.contact.userData.username === callerId) {
            // caller audio stream
            if (reduxStore.getState().application.audioStreamIsActive) {
                addNotificationAndShowDispatch('info.aud_lst', 'info', DISPLAY_ONLY_IN_SESSION);
            }

            deactivateAudioStreamDispatcherDispatch();
        }

        stream.removeFromDiv('incomingStreamToDispatcher', 'stream-media-' + stream.streamId);
    }

    // CALLER
    if (store.type === 'caller') {
        if (stream.type === 'video') {
            stream.removeFromDiv('bidiContainerCaller__inner', 'bidiStream-' + stream.streamId);
            var bidiContainer = document.getElementById('bidiContainerCaller__inner');
            bidiContainer.innerHTML = '';
        } else {
            stream.removeFromDiv('incomingStreamToCaller', 'stream-media-' + stream.streamId);
        }
    }

    // CONFERENCE USER
    if (store.type === 'conference') {
        stream.removeFromDiv('incomingStreamToConferenceUser', 'stream-media-' + stream.streamId);
    }
};

// ADD AND REMOVE STREAMS TO CONTAINER CALLER

export const addBidiStreamToCallerContainer = stream => {
    stream.addInDiv('bidiContainerCaller__inner', 'bidiStream-' + stream.streamId, {}, true);
};

export const addAllStreamsToContainerCaller = () => {
    if (reduxStore.getState().streams.dispatcherAudioStream) {
        let stream = reduxStore.getState().streams.dispatcherAudioStream;
        stream.addInDiv('incomingStreamToCaller', 'stream-media-' + stream.streamId, {}, false);
    }
    if (reduxStore.getState().streams.conferenceUserStreams.length !== 0) {
        reduxStore.getState().streams.conferenceUserStreams.forEach(stream => {
            stream.addInDiv('incomingStreamToCaller', 'stream-media-' + stream.streamId, {}, false);
        });
    }
};

export const removeAllStreamsFromContainerCaller = () => {
    if (reduxStore.getState().streams.dispatcherAudioStream) {
        let stream = reduxStore.getState().streams.dispatcherAudioStream;
        stream.removeFromDiv('incomingStreamToCaller', 'stream-media-' + stream.streamId, {}, false);
    }
    if (reduxStore.getState().streams.conferenceUserStreams.length !== 0) {
        reduxStore.getState().streams.conferenceUserStreams.forEach(stream => {
            stream.removeFromDiv('incomingStreamToCaller', 'stream-media-' + stream.streamId, {}, false);
        });
    }
};

// ADD AND REMOVE STREAMS TO CONTAINER DISPATCHER

export const readdAllStreamsToContainerDispatcher = () => {
    removeAllStreamsFromContainerDispatcher();
    addAllStreamsToContainerDispatcher();
};

export const readdAllStreamsToContainerCaller = () => {
    removeAllStreamsFromContainerCaller();
    addAllStreamsToContainerCaller();
};

export const addAllStreamsToContainerDispatcher = () => {
    if (reduxStore.getState().streams.callerAudioStream) {
        let stream = reduxStore.getState().streams.callerAudioStream;
        stream.addInDiv('incomingStreamToDispatcher', 'stream-media-' + stream.streamId, {}, false);
    }
    if (reduxStore.getState().streams.conferenceUserStreams.length !== 0) {
        reduxStore.getState().streams.conferenceUserStreams.forEach(stream => {
            stream.addInDiv('incomingStreamToDispatcher', 'stream-media-' + stream.streamId, {}, false);
        });
    }
};

export const removeAllStreamsFromContainerDispatcher = () => {
    if (reduxStore.getState().streams.callerAudioStream) {
        let stream = reduxStore.getState().streams.callerAudioStream;
        stream.removeFromDiv('incomingStreamToDispatcher', 'stream-media-' + stream.streamId, {}, false);
    }
    if (reduxStore.getState().streams.conferenceUserStreams.length !== 0) {
        reduxStore.getState().streams.conferenceUserStreams.forEach(stream => {
            stream.removeFromDiv('incomingStreamToDispatcher', 'stream-media-' + stream.streamId, {}, false);
        });
    }
};

// ADD AND REMOVE STREAMS CONTAINER CONFERENCE USER

export const addAllStreamsToContainerConferenceUsers = () => {
    if (reduxStore.getState().streams.dispatcherStream) {
        addStreamToConferenceUserContainer(reduxStore.getState().streams.dispatcherStream);
    }
    if (reduxStore.getState().streams.dispatcherAudioStream) {
        let stream = reduxStore.getState().streams.dispatcherAudioStream;
        stream.addInDiv('incomingStreamToConferenceUser', 'stream-media-' + stream.streamId, {}, false);
    }
    if (reduxStore.getState().streams.conferenceUserStreams.length !== 0) {
        reduxStore.getState().streams.conferenceUserStreams.forEach(stream => {
            stream.addInDiv('incomingStreamToConferenceUser', 'stream-media-' + stream.streamId, {}, false);
        });
    }
};

export const removeAllStreamsFromContainerConferenceUsers = () => {
    if (reduxStore.getState().streams.dispatcherStream) {
        handleRemovalOfIncomingStream(reduxStore.getState().streams.dispatcherStream, conferenceStore);
    }
    if (reduxStore.getState().streams.dispatcherAudioStream) {
        let stream = reduxStore.getState().streams.dispatcherAudioStream;
        stream.removeFromDiv('incomingStreamToConferenceUser', 'stream-media-' + stream.streamId, {}, false);
    }
    if (reduxStore.getState().streams.conferenceUserStreams.length !== 0) {
        reduxStore.getState().streams.conferenceUserStreams.forEach(stream => {
            stream.removeFromDiv('incomingStreamToConferenceUser', 'stream-media-' + stream.streamId, {}, false);
        });
    }
};

export const removeScreenShareStreamsFromContainerConferenceUsers = () => {
    const streamDiv = document.getElementById('incomingStreamToConferenceUser');
    if (typeof streamDiv !== 'undefined' && streamDiv !== null) {
        // Remove old video elements from id incomingStreamToConferenceUser if any exist

        var children = streamDiv.childNodes;
        children.forEach(function (videoElement) {
            if (videoElement.nodeName.toLowerCase() === 'video') {
                streamDiv.removeChild(videoElement);
            }
        });
    }
};

const addStreamToConferenceUserContainer = stream => {
    const streamDiv = document.getElementById('incomingStreamToConferenceUser');
    if (typeof streamDiv !== 'undefined' && streamDiv !== null) {
        var children = streamDiv.childNodes;
    }

    // Check if stream is a screen share
    if (stream.callAudioActive === false) {
        // Create new video element with controls to allow user full screen viewing
        const mediaElt = document.createElement('video');
        mediaElt.id = 'stream';
        mediaElt.autoplay = true;
        mediaElt.muted = true;
        mediaElt.className = 'videoElement';
        mediaElt.controls = true;
        mediaElt.playsinline = true;

        NodeList.prototype.forEach = Array.prototype.forEach;
        if (streamDiv !== null) {
            // Attach controls to incoming video stream
            stream.attachToElement(mediaElt);
            streamDiv.appendChild(mediaElt);

            // Workaround to prevent pausing of screen share stream
            let video = document.querySelector('video');
            video.addEventListener('pause', e => {
                video.play();
                e.preventDefault();
            });
        }
    }

    if (typeof streamDiv !== 'undefined' && streamDiv !== null) {
        stream.addInDiv('incomingStreamToConferenceUser', 'stream-media-' + stream.streamId, {}, false);

        // Remove unused stream elements from id incomingStreamToConferenceUser if any exist
        children = streamDiv.childNodes;
        children.forEach(function (videoElement) {
            if (videoElement.nodeName.toLowerCase() === 'video') {
                if (videoElement.id !== 'stream') {
                    streamDiv.removeChild(videoElement);
                }
            }
        });
    }
};

// STREAM CHANGED
const handleStreamChangedDispatcher = streamInfo => {
    changeStream(streamInfo, dispatcherStore);
};

const handleStreamChangedCaller = streamInfo => {
    changeStream(streamInfo, callerStore);
};

const handleStreamChangedConferenceUser = streamInfo => {
    changeStream(streamInfo, conferenceStore);
};

const changeStream = (streamInfo, store) => {
    if (streamInfo.isRemote === true) {
        // DISPATCHER
        if (store.type === 'dispatcher') {
            if (streamInfo.listEventType === 'added') {
                dispatchAddStreamInfo(streamInfo);
                store.connectedConversation
                    .subscribeToMedia(streamInfo.streamId)
                    .then(stream => {
                        console.log('subscribeToMedia succeeded');
                    })
                    .catch(err => {
                        console.error('subscribeToMedia error', err);
                    });
            }
            if (streamInfo.listEventType === 'removed') {
                dispatchRemoveStreamInfo(streamInfo.streamId);
            }
            if (streamInfo.listEventType === 'updated') {
                for (const previousStreamInfo of reduxStore.getState().conferencing.streamInfo) {
                    if (streamInfo.streamId === previousStreamInfo.streamId) {
                        dispatchRemoveStreamInfo(previousStreamInfo.streamId);
                        dispatchAddStreamInfo(streamInfo);
                    }
                }
            }
        }
        // CALLER
        if (store.type === 'caller') {
            if (streamInfo.listEventType === 'added') {
                dispatchAddStreamInfo(streamInfo);
                store.connectedConversation
                    .subscribeToMedia(streamInfo.streamId)
                    .then(stream => {
                        console.log('subscribeToMedia succeeded');
                    })
                    .catch(err => {
                        console.error('subscribeToMedia error', err);
                    });
            }
            if (streamInfo.listEventType === 'removed') {
                dispatchRemoveStreamInfo(streamInfo.streamId);
            }
            if (streamInfo.listEventType === 'updated') {
                for (const previousStreamInfo of reduxStore.getState().conferencing.streamInfo) {
                    if (streamInfo.streamId === previousStreamInfo.streamId) {
                        dispatchRemoveStreamInfo(previousStreamInfo.streamId);
                        dispatchAddStreamInfo(streamInfo);
                    }
                }
            }
        }
        // CONFERENCE USER
        if (store.type === 'conference') {
            if (streamInfo.listEventType === 'added') {
                dispatchAddStreamInfo(streamInfo);
                store.connectedConversation
                    .subscribeToMedia(streamInfo.streamId)
                    .then(stream => {
                        console.log('subscribeToMedia succeeded');
                    })
                    .catch(err => {
                        console.error('subscribeToMedia error', err);
                    });
            }
            if (streamInfo.listEventType === 'removed') {
                dispatchRemoveStreamInfo(streamInfo.streamId);
            }
            if (streamInfo.listEventType === 'updated') {
                for (const previousStreamInfo of reduxStore.getState().conferencing.streamInfo) {
                    if (streamInfo.streamId === previousStreamInfo.streamId) {
                        dispatchRemoveStreamInfo(previousStreamInfo.streamId);
                        dispatchAddStreamInfo(streamInfo);
                    }
                }
            }
        }
    }
};

// CONTACT JOINED
const handleContactJoinedDispatcher = contactInfo => {
    const { callerId } = getURLParams();
    // caller handling
    if (contactInfo.userData.username === callerId) {
        dispatcherStore.sender = contactInfo;
        if (DEBUG) addLogDispatch(['call invitation accepted']);
        createKpiLog('infoConnectionEstablished');

        dispatcherStore.establishHeartbeat();
        connectionEstablishedDispatch();

        dispatcherStore.callbackOnIncoming && dispatcherStore.callbackOnIncoming();

        for (const callback of dispatcherStore.newCallCallbacks) {
            if (typeof callback == 'function') {
                callback();
            }
        }
    }

    dispatcherStore.renderUserList();
};

const handleContactJoinedConferenceUser = contactInfo => {
    conferenceStore.renderUserList();
};

// CONTACT LEFT
const handleContactLeftDispatcher = contactInfo => {
    const { callerId } = getURLParams();
    if (dispatcherStore.sender !== null) {
        if (contactInfo.userData.username === callerId) {
            connectionLostDispatch();
        }
    }

    dispatcherStore.renderUserList();
};

const handleContactLeftConferenceUser = contactInfo => {
    conferenceStore.renderUserList();
    if (contactInfo.userData.username === 'dispatcher') conferenceStore.onLeaveHandler();
};

const handleContactLeftCaller = contactInfo => {
    if (contactInfo.userData.username === 'dispatcher') {
        if (callerStore.connectedConversation !== null) {
            callerStore.connectedConversation.leave();
        }
        if (callerStore.disconnectCallback) {
            callerStore.disconnectCallback();
            callerStore.clearAllTimeouts();
            clearInterval(callerStore.checkHeartbeatInterval);
        }
    }
};
