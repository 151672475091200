import { errorLog } from './logging';
import { store } from '../store/DispatcherStore';
import { API_RTC_RESOURCE_TIMEOUT, API_RTC_SDK_SCRIPT_TIMEOUT, API_RTC_TIMEOUT, DEBUG, PRIMARY_WEB_RTC_PLATFORM, SECONDARY_WEB_RTC_PLATFORM } from '../config';
import { getApizeeKey, postWebRtcAlias } from '../api/backendApi';
import { dispatchLoadWebRtc } from '../redux/actions/application';
import { isOnStartPage } from './helper';

export const webRtcLoader = () => {
    const primaryRtcPlatformScript = process.env.REACT_APP_PRIMARY_WEBRTC_JS_URL;
    const secondaryRtcPlatformScript = process.env.REACT_APP_SECONDARY_WEBRTC_JS_URL;

    const primaryRtcPlatformUrl = process.env.REACT_APP_PRIMARY_RTC_PLATFORM_URL;
    const secondaryRtcPlatformUrl = process.env.REACT_APP_SECONDARY_RTC_PLATFORM_URL;

    let rtcPlatformErrorTimer = null; 
    let rtcResourceErrorTimer = null;
    
    let primaryApiRtcTimer = null;
    let secondaryApiRtcTimer = null;

    let loadInterval = null;
    let dispatcherDashboardLoadedInterval = null;

    // remove any previously existing api rtc script
    let existingScript = document.getElementById('api-rtc-script');
    if (existingScript) {
        document.body.removeChild(existingScript);
    }

    let script = document.createElement('script');
    script.setAttribute('id', 'api-rtc-script');

    const platformNotAvailable = e => {
        errorLog({
            message: 'apiRTC not a available - dispatcher',
            error: e,
            eventId: 'APIRTC_SCRIPT_LOADING_DISPATCHER',
        });
        window.setTimeout(() => {
            window.location.href = '/error.html?error=apiRTC script currently not available';
        }, 100);
    };

    const resourceNotAvailable = e => {
        errorLog({
            message: 'apiRTC not a available - dispatcher',
            error: e,
            eventId: 'APIRTC_SCRIPT_LOADING_DISPATCHER',
        });
        window.setTimeout(() => {
            window.location.href = '/error.html?error=apiRTC script currently not available';
        }, 100);
    };

    // show error page if platform not available
    const startPlatformTimer = () => {
        rtcPlatformErrorTimer = setTimeout(() => {
            if (DEBUG) console.log('timeout while loading apiRTC script');

            platformNotAvailable({ stack: 'TIMEOUT LOADING' });
        }, API_RTC_TIMEOUT);
    };

    // show error page if platform not available
    const startResourceTimer = () => {
        rtcResourceErrorTimer = setTimeout(() => {
            if (DEBUG) console.log('timeout while loading apiRTC script');

            resourceNotAvailable({ stack: 'TIMEOUT LOADING' });
            clearTimeout(rtcResourceErrorTimer);
            clearInterval(dispatcherDashboardLoadedInterval);
        }, API_RTC_RESOURCE_TIMEOUT);
    };

    // stop resource timer if dispatcher dashboard has loaded
    const startDispatcherDashboardLoadingInterval = () => {
        setInterval(() => {
            if (!isOnStartPage()) {
                clearTimeout(rtcResourceErrorTimer);
                clearInterval(dispatcherDashboardLoadedInterval);
            }
        }, 500);
    }

    // callback after apiRTC script is loaded
    const handleScriptLoad = async () => {
        clearTimeout(rtcPlatformErrorTimer);
        if (DEBUG) console.log('timeout cleared');
        store.apiRTC = (window as any).apiRTC; // eslint-disable-line
        if (store.apiRTC.CloudApi.cloudUrl.includes(PRIMARY_WEB_RTC_PLATFORM)) {
            // hds platform
            store.webRtcAlias = primaryRtcPlatformUrl;
            store.apiKey = await getApizeeKey(store.type, PRIMARY_WEB_RTC_PLATFORM);
        } else if (store.apiRTC.CloudApi.cloudUrl.includes(SECONDARY_WEB_RTC_PLATFORM)) {
            // cloud platform
            store.webRtcAlias = secondaryRtcPlatformUrl;
            store.apiKey = await getApizeeKey(store.type, SECONDARY_WEB_RTC_PLATFORM);
        }

        if (script.src.includes(PRIMARY_WEB_RTC_PLATFORM)) {
            await postWebRtcAlias(primaryRtcPlatformScript);
            store.isPrimaryPlatformUsed = true;
        } else if (script.src.includes(SECONDARY_WEB_RTC_PLATFORM) || script.src.includes('cdn')) {
            await postWebRtcAlias(secondaryRtcPlatformScript);
            store.isPrimaryPlatformUsed = false;
        }
        dispatchLoadWebRtc();
    };

    const checkLoaded = () => {
        if ((window as any).apiRTC) {
            clearInterval(primaryApiRtcTimer);
            clearInterval(secondaryApiRtcTimer);
            clearInterval(loadInterval);
            handleScriptLoad();
        }
    };
  
    const loadPrimaryScriptAndStartTimer = () => {
        loadPrimaryWebRtcScript();
        primaryApiRtcTimer = setTimeout(() => {
            if (DEBUG) console.log('timeout while loading primary apiRTC script. loading secondary script...');
            loadSecondaryScriptAndStartTimer();
        }, API_RTC_SDK_SCRIPT_TIMEOUT);
    }
    
    const loadPrimaryWebRtcScript = () => {
        script.setAttribute('src', addTimestampToSDK(primaryRtcPlatformScript));
        document.body.appendChild(script);
    }
    
    const loadSecondaryScriptAndStartTimer = () => {
        loadSecondaryWebRtcScript();
        secondaryApiRtcTimer = setTimeout(() => {
            if (DEBUG) console.log('timeout while loading secondary apiRTC script.');
        }, API_RTC_SDK_SCRIPT_TIMEOUT);
    }

    const loadSecondaryWebRtcScript = () => {
        document.body.removeChild(script);
        script = document.createElement('script');
        script.setAttribute('id', 'api-rtc-script');
        script.setAttribute('src', addTimestampToSDK(secondaryRtcPlatformScript));
        document.body.appendChild(script);
    }

    const addTimestampToSDK = (script: string) => {
        // Prevents script loading from browser cache
        var timestamp = Math.floor(Date.now() / 1000);
        var sdkUrl = script + "?timestamp=" + timestamp;

        return sdkUrl;
    }

    loadPrimaryScriptAndStartTimer();
    startPlatformTimer();
    startResourceTimer();
    startDispatcherDashboardLoadingInterval();
    loadInterval = setInterval(checkLoaded, 200);
}