import { DEBUG } from '../config';
import { addLogDispatch } from '../redux/actions/logs';
import { dataLog } from './logging';
import { addVideoStreamDispatch, removeVideoStreamDispatch } from '../redux/actions/application';
import { pointer } from '../components/Icons/Pointer';

/*globals apiRTC*/

/**
 * removes the current stream container
 */
export const removeStreamContainer = () => {
    const elm = document.getElementById('stream');
    if (elm) {
        removeVideoStreamDispatch();
        elm.remove();
    }
};

/**
 * add the video stream to a dom video object
 * @param {object} stream video stream object
 * @param {boolean} showControls
 */
export const addCallerVideoStreamInDiv = (stream, showControls) => {
    const streamDiv = document.getElementById('videoContainer__inner');
    streamDiv.innerHTML = '';

    const mediaElt = document.createElement('video');
    mediaElt.id = 'stream';
    mediaElt.autoplay = true;
    mediaElt.muted = true;
    mediaElt.className = 'videoElement';
    mediaElt.controls = showControls;
    mediaElt.playsinline = true;

    const pointerElement = document.createElement('div');
    pointerElement.id = 'pointer';
    pointerElement.className = 'pointerElement';
    pointerElement.innerHTML = pointer;

    let funcFixIoS = () => {
        let promise = mediaElt.play();

        if (promise !== undefined) {
            promise
                .then(function () {
                    // Autoplay started!
                    if (DEBUG) addLogDispatch(['Autoplay started']);

                    document.removeEventListener('touchstart', funcFixIoS);
                })
                .catch(error => {
                    // Autoplay was prevented.
                    if (DEBUG) addLogDispatch(['Autoplay was prevented']);
                    dataLog({
                        message: 'Autoplay was prevented.',
                        eventId: 'AUTOPLAY_PREVENT',
                    });
                });
        }
    };

    window.addEventListener('pauseVideo', () => {
        mediaElt.controls = false;
        mediaElt.pause();
    });

    window.addEventListener('unpauseVideo', () => {
        mediaElt.controls = showControls;
        mediaElt.play();
    });

    stream.attachToElement(mediaElt);
    streamDiv.appendChild(mediaElt);
    streamDiv.appendChild(pointerElement);
    addVideoStreamDispatch();

    let playPromise = mediaElt.play();
    if (playPromise !== undefined) {
        playPromise
            .then(function () {
                if (DEBUG) addLogDispatch(['Autoplay started']);
            })
            .catch(error => {
                if (apiRTC.osName === 'iOS') {
                    if (DEBUG) addLogDispatch(['iOS : Autoplay was prevented, activating touch event to start media play']);
                    dataLog({
                        message: 'Autoplay was prevented.',
                        eventId: 'AUTOPLAY_PREVENT',
                    });

                    document.addEventListener('touchstart', funcFixIoS);
                } else {
                    if (DEBUG) addLogDispatch(['Autoplay was prevented']);
                    dataLog({
                        message: 'Autoplay was prevented.',
                        eventId: 'AUTOPLAY_PREVENT',
                    });
                }
            });
    }
};

/**
 * setup eventlisteners for stream errors
 * @param {object} call
 */
export const streamHandling = call => {
    call.on('localStreamAvailable', stream => {
        if (DEBUG) addLogDispatch(['localStreamAvailable']);
        addCallerVideoStreamInDiv(stream, false);
        stream.on('stopped', () => {
            if (DEBUG) addLogDispatch(['Stream stopped']);
            removeStreamContainer();
        });
    })
        .on('streamAdded', stream => {
            if (DEBUG) addLogDispatch(['stream added']);
            addCallerVideoStreamInDiv(stream, true);
            stream.on('stopped', () => {
                if (DEBUG) addLogDispatch(['Stream stopped']);
                removeStreamContainer();
            });
        })
        .on('streamRemoved', e => {
            if (DEBUG) addLogDispatch(['stream removed', e]);
        })
        .on('userMediaError', e => {
            if (DEBUG) addLogDispatch(['user media error', e]);
        })
        .on('desktopCapture', e => {
            if (DEBUG) addLogDispatch(['desktopCapture event : ', e]);
        })
        .on('hangup', () => {
            if (DEBUG) addLogDispatch(['hangup']);
        });
};

/**
 * add the video stream to a dom video object
 * @param {object} stream video stream object
 * @param {boolean} showControls
 */
export const addDispatcherVideoStreamInDiv = (stream, showControls) => {
    const streamDiv = document.getElementById('bidiContainer__inner');
    streamDiv.innerHTML = '';

    const mediaElt = document.createElement('video');
    mediaElt.id = 'stream';
    mediaElt.autoplay = true;
    mediaElt.muted = true;
    mediaElt.className = 'videoElement';
    mediaElt.controls = showControls;
    mediaElt.playsinline = true;

    let funcFixIoS = () => {
        let promise = mediaElt.play();

        if (promise !== undefined) {
            promise
                .then(function () {
                    // Autoplay started!
                    if (DEBUG) addLogDispatch(['Autoplay started']);

                    document.removeEventListener('touchstart', funcFixIoS);
                })
                .catch(error => {
                    // Autoplay was prevented.
                    if (DEBUG) addLogDispatch(['Autoplay was prevented']);
                    dataLog({
                        message: 'Autoplay was prevented.',
                        eventId: 'AUTOPLAY_PREVENT',
                    });
                });
        }
    };

    window.addEventListener('pauseVideo', () => {
        mediaElt.controls = false;
        mediaElt.pause();
    });

    window.addEventListener('unpauseVideo', () => {
        mediaElt.controls = showControls;
        mediaElt.play();
    });

    stream.attachToElement(mediaElt);
    streamDiv.appendChild(mediaElt);

    // TODO: Replace this with addBidiVideoStream()
    // addVideoStreamDispatch();

    let playPromise = mediaElt.play();
    if (playPromise !== undefined) {
        playPromise
            .then(function () {
                if (DEBUG) addLogDispatch(['Autoplay started']);
            })
            .catch(error => {
                if (apiRTC.osName === 'iOS') {
                    if (DEBUG) addLogDispatch(['iOS : Autoplay was prevented, activating touch event to start media play']);
                    dataLog({
                        message: 'Autoplay was prevented.',
                        eventId: 'AUTOPLAY_PREVENT',
                    });

                    document.addEventListener('touchstart', funcFixIoS);
                } else {
                    if (DEBUG) addLogDispatch(['Autoplay was prevented']);
                    dataLog({
                        message: 'Autoplay was prevented.',
                        eventId: 'AUTOPLAY_PREVENT',
                    });
                }
            });
    }
};
