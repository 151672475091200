import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { store } from '../../store/DispatcherStore';
import { oauthStore } from '../../store/OAuthStore';

import './LogoutButton.scss';
import { calcDuration } from '../../helper/logging';
import { createKpiLog, replaceText } from '../../helper/helper';
import { postLogout } from '../../api/backendApi';

/**
 * Logout Button
 * logs the user out from the application (frontend & backend)
 */

class LogoutButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logoutTriggered: false,
        };
    }

    handleClick = async () => {
        const additionalStates = {
            0: calcDuration('authSession'),
        };

        createKpiLog('infoDispatcherLogout', '', additionalStates);

        this.props.beforeLogout();

        await postLogout();

        store.logout();
        oauthStore.logout();
        this.setState({ logoutTriggered: true });
    };

    render() {
        if (this.state.logoutTriggered) {
            return <Redirect to={'/login'} />;
        }
        return (
            <button className="btn btn--secondary" onClick={this.handleClick}>
                {replaceText(this.props.texts, 'logout.button')}
            </button>
        );
    }
}

// PropTypes for this Component
LogoutButton.propTypes = {
    match: PropTypes.any,
    beforeLogout: PropTypes.func,
    texts: PropTypes.any,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
    };
};

// Connect Props and Dispatch to Component
export default connect(mapStateToProps)(LogoutButton);
