import React from 'react';

function Pause() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18">
            <g
                id="Group_4701"
                data-name="Group 4701"
                transform="translate(-797 -28)">
                <circle
                    id="Ellipse_292"
                    data-name="Ellipse 292"
                    cx="9"
                    cy="9"
                    r="9"
                    transform="translate(797 28)"
                    fill="#fff"
                />
                <g
                    id="Group_4701-2"
                    data-name="Group 4701"
                    transform="translate(-6.75 10.643)">
                    <line
                        id="Line_193"
                        data-name="Line 193"
                        y2="8"
                        transform="translate(810.5 22.357)"
                        fill="none"
                        stroke="rgba(0,0,0,0.9)"
                        strokeLinecap="round"
                        strokeWidth="2.5"
                    />
                    <line
                        id="Line_194"
                        data-name="Line 194"
                        y2="8"
                        transform="translate(815 22.357)"
                        fill="none"
                        stroke="rgba(0,0,0,0.9)"
                        strokeLinecap="round"
                        strokeWidth="2.5"
                    />
                </g>
            </g>
        </svg>
    );
}

export default Pause;
