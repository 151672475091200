import { ADD_LOG } from '../actions/actionTypes';

export const initialState = [];

/**
 * logs reducer
 * see https://redux.js.org/
 * @param {object} state
 * @param {object} action
 */

const logsReducer = (state = initialState, action) => {
    if (action.type === ADD_LOG) {
        state = [...state, action.log];
    }

    return state;
};

export default logsReducer;
