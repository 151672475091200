import { getApizeeKey, getAuthtoken, getImage, getSessionEndMessagesConference } from '../api/backendApi';
import { DEBUG, PRIMARY_WEB_RTC_PLATFORM, SECONDARY_WEB_RTC_PLATFORM } from '../config';
import { errorLog } from '../helper/logging';
import {
    createUserDisplayName,
    enterConversation,
    getURLParams,
    loadEventListenersConferenceUser,
    unloadEventListenersConferenceUser,
} from '../helper/rtcFlowHandling';
import {
    activateScreenSharingDispatch,
    deactivateScreenSharingDispatch,
    muteMicContactDispatch,
    setIsLeavingConferenceDispatch,
} from '../redux/actions/conferencing';
import { addLogDispatch } from '../redux/actions/logs';
import { addNotificationAndShowDispatch } from '../redux/actions/notifications';
import { dispatchAddOwnStreamConferenceUser } from '../redux/actions/stream';
import { ONLY_AUDIO } from '../redux/reducers/streams';
import reduxStore from '../redux/store';
class ConferenceStore {
    authToken = null;

    apiRTC = null;
    apiKey = null;
    userAgent = null;

    connectedSession = null;
    connectedConversation = null;
    sessionEndMessages = null;
    imageLogo = null;

    dispatcher = null;
    joinRequestIsGranted = null;

    localStream = null;
    type = 'conference';

    sessionId = null;

    /**
     * handles the request token generation
     */
    async requestToken() {
        const { sessionId } = getURLParams();

        this.sessionId = sessionId;

        if (!this.authToken) {
            this.authToken = await getAuthtoken(sessionId);
            if (this.authToken) {
                // Proceed
            } else {
                errorLog({
                    sessionId: sessionId,
                    message: `Error getting Authtoken`,
                    error: { stack: 'no authtoken available' },
                    eventId: 'ERROR_AUTHTOKEN',
                });
                throw new Error(`Error getting Authtoken`);
            }
        }
    }

    initConnectionThen(initCallback, errorCallback) {
        this.requestToken()
            .then(async () => {
                this.sessionEndMessages = await getSessionEndMessagesConference(this.authToken);
                this.imageLogo = await getImage({ type: 'blob', endpoint: process.env.REACT_APP_CONFERENCE_LOGO_ENDPOINT });
                this.registerUser(initCallback);
            })
            .catch(error => {
                errorCallback(error);
            });
    }

    /**
     * register the current useragent with apiRTC, then call the callback
     * @param {function} initCallback
     */
    async registerUser(initCallback) {
        if (window.apiRTC.CloudApi.cloudUrl.includes(PRIMARY_WEB_RTC_PLATFORM)) {
            // hds platform
            conferenceStore.apiKey = await getApizeeKey(this.type, PRIMARY_WEB_RTC_PLATFORM);
        } else if (window.apiRTC.CloudApi.cloudUrl.includes(SECONDARY_WEB_RTC_PLATFORM)) {
            // cloud platform
            conferenceStore.apiKey = await getApizeeKey(this.type, SECONDARY_WEB_RTC_PLATFORM);
        }

        conferenceStore.userAgent = new window.apiRTC.UserAgent({
            uri: 'apzkey:' + conferenceStore.apiKey,
        });

        conferenceStore.userAgent
            .register({
                cloudUrl: window.apiRTC.CloudApi.cloudUrl,
                token: this.authToken,
            })
            .then(session => {
                // Save session
                conferenceStore.connectedSession = session;
                conferenceStore.setupMessageListener();

                if (DEBUG) addLogDispatch(['user agent registered session', session.id]);

                initCallback();
            });
    }

    setupMessageListener() {
        conferenceStore.connectedSession.removeListener('contactMessage');
        conferenceStore.connectedSession.on('contactMessage', conferenceStore.handleContactMessage);
    }

    sendJoinRequest() {
        const message = {
            data: 'joinRequestMessage',
        };

        conferenceStore.sendMessage(message);
    }

    /**
     * send a given message to dispatcher via webrtc
     * @param {object} message2Send
     * @param {boolean} ping - is heartbeat ping
     */
    sendMessage(message2Send, ping = false) {
        const message = JSON.stringify(message2Send);
        const { sessionId } = getURLParams();
        if (this.dispatcher) {
            this.dispatcher
                .sendMessage(message)
                .then(() => {
                    if (DEBUG) addLogDispatch(['Send message', message]);
                })
                .catch(error => {
                    if (DEBUG) addLogDispatch(['error sending messsage', message, error]);

                    if (!ping) {
                        errorLog({
                            sessionId: sessionId,
                            message: `Error sending message via rtc - conference user - ${message}`,
                            error: error,
                            eventId: 'MESSAGE_SEND',
                        });
                    }
                });
        }
    }

    handleContactMessage = e => {
        const message = JSON.parse(e.content);
        if (message.data === 'toggleMicrophone') {
            conferenceStore.muteMic();
            muteMicContactDispatch();
        }

        if (message.data === 'screenSharingToggled') {
            this.toggleScreenSharing = message.state;
            if (this.toggleScreenSharing) {
                if (!reduxStore.getState().conferencing.screenSharingIsActive) activateScreenSharingDispatch();
            } else {
                deactivateScreenSharingDispatch();
            }
        }

        if (message.data === 'dispatcherLeft') {
            setIsLeavingConferenceDispatch();
        }

        if (message.data === 'joinRequestIsGranted') {
            if (conferenceStore.joinRequestIsGranted === null) {
                conferenceStore.joinRequestIsGranted = true;
            }
        }

        if (message.data === 'joinRequestIsDeclined') {
            if (conferenceStore.joinRequestIsGranted === null) {
                conferenceStore.joinRequestIsGranted = false;
            }
        }
    };

    updateUserName(userName) {
        conferenceStore.connectedSession.setUserData({ username: userName });
    }

    joinConversation(contactId) {
        return new Promise(resolve => {
            const { callerId } = getURLParams();
            conferenceStore.dispatcher = conferenceStore.connectedSession.getOrCreateContact(callerId);

            createUserDisplayName(conferenceStore, contactId);

            enterConversation(conferenceStore);
            loadEventListenersConferenceUser();

            conferenceStore.userAgent
                .createStream(ONLY_AUDIO)
                .then(stream => {
                    const options = {
                        audioLabels: ['conferenceUserAudio'],
                    };
                    conferenceStore.localStream = stream;
                    dispatchAddOwnStreamConferenceUser(stream);
                    conferenceStore.connectedConversation.join().then(() => {
                        conferenceStore.connectedConversation.publish(conferenceStore.localStream, options);
                    });
                })
                .catch(e => {
                    addNotificationAndShowDispatch('error.mic.psn', 'error');
                });

            resolve('Joined');
        });
    }

    renderUserList() {
        const { callerId } = getURLParams();
        var contacts = conferenceStore.connectedConversation.getContacts();
        var div = document.getElementById('active-users');
        if (div !== null) {
            div.innerHTML = '';
        }
        var keys = Object.keys(contacts);
        // Reverse array to render list in order of which conference users join
        keys.reverse();
        if (div !== null) {
            for (const element of keys) {
                if (contacts[element].getUsername() !== callerId) {
                    div.innerHTML += '<div>' + contacts[element].getUsername() + '</div>';
                }
            }
        }
    }

    muteMic() {
        conferenceStore.localStream.disableAudio();
    }

    unmuteMic() {
        conferenceStore.localStream.enableAudio();
    }

    leaveConference() {
        unloadEventListenersConferenceUser();

        if (conferenceStore.connectedConversation !== null) {
            conferenceStore.connectedConversation
                .leave()
                .then(() => {
                    console.debug('Conversation leave OK');
                    conferenceStore.connectedConversation.destroy();

                    conferenceStore.connectedConversation = null;
                })
                .catch(err => {
                    console.error('Conversation leave error', err);
                });
        }

        if (conferenceStore.localStream !== null) {
            conferenceStore.localStream.release();
        }

        this.userAgent
            .unregister()
            .then(() => {
                console.log('Disconnected from rtc platform');
            })
            .catch(error => {
                console.log('error disconnecting during unregistration: ', error);
            });
    }
}
export let conferenceStore = new ConferenceStore();
