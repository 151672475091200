import React from 'react';

function NoChat() {
    return (
        // <svg viewBox="0 0 288 310" xmlns="http://www.w3.org/2000/svg">
        //     <g fill="#e6e7e8">
        //         <path d="M272.121 241.317H16.996c-2.6 0-4.717-2.117-4.717-4.734v-14.166h264.567v14.166a4.729 4.729 0 01-4.725 4.734zm-108.667 28.341h-37.8v-18.9h37.8v18.9zm18.9 18.9h-75.591v-9.45h75.591v9.45zM16.996 61.783h132.283v28.35H26.446a4.718 4.718 0 00-4.717 4.717v75.592c0 2.616 2.117 4.733 4.717 4.733h108.658a4.723 4.723 0 004.725-4.733v-44.875l9.45-7.092v70.867c0 7.825 6.35 14.175 14.175 14.175h85.042c7.825 0 14.175-6.35 14.175-14.175V61.783h9.45a4.727 4.727 0 014.725 4.725v146.459H12.279V66.508a4.725 4.725 0 014.717-4.725zm141.733-33.075a4.72 4.72 0 014.725-4.716h85.042a4.72 4.72 0 014.725 4.716v132.284h-94.492v-49.6l17.009-12.759a4.731 4.731 0 001.65-5.275 4.734 4.734 0 00-4.484-3.225h-14.175V28.708zm0 141.734h94.492v18.9a4.727 4.727 0 01-4.725 4.725h-85.042a4.727 4.727 0 01-4.725-4.725v-18.9zm-26.458-51.017a4.734 4.734 0 00-1.883 3.775v42.517H31.171V99.575h127.558l-26.458 19.85zm139.85-67.092h-9.45V28.708c0-7.825-6.35-14.166-14.175-14.166h-85.042c-7.825 0-14.175 6.341-14.175 14.166v23.625H16.996c-7.825 0-14.175 6.35-14.175 14.175v170.075c0 7.834 6.35 14.175 14.175 14.175h99.217v18.9h-14.176a4.726 4.726 0 00-4.724 4.725v14.175h-9.45V298h113.391v-9.442h-9.45v-14.175a4.729 4.729 0 00-4.733-4.725h-14.167v-18.9h99.217c7.825 0 14.175-6.341 14.175-14.175V66.508c0-7.825-6.35-14.175-14.175-14.175z" />
        //         <path d="M.062 12.158l275.659 297.025 12.217-11.333L12.279.817" />
        //     </g>
        // </svg>

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170.38 141.314">
            <g fill="none" stroke="#e6e7e8" strokeWidth="8">
                <g>
                    <path d="M165.127 31.401a2.025 2.025 0 0 0-2.212.44l-17.673 17.673H70.981a14.224 14.224 0 0 0-14.208 14.208v36.535a14.224 14.224 0 0 0 14.208 14.209h81.192a14.224 14.224 0 0 0 14.208-14.208V33.276a2.033 2.033 0 0 0-1.254-1.875Z" />
                    <path d="M5.252 108.222a2.025 2.025 0 0 0 2.212-.44l17.673-17.673H99.4a14.224 14.224 0 0 0 14.208-14.208V39.365A14.224 14.224 0 0 0 99.4 25.157H18.208A14.224 14.224 0 0 0 4 39.365v66.982a2.033 2.033 0 0 0 1.252 1.875Z" />
                </g>
                <path strokeLinecap="round" d="m148.5 135.657-130-130" />
            </g>
        </svg>
    );
}

export default NoChat;
