import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Download from '../Icons/Download';
import { IMAGE_NAME, IMAGE_TYPE_ENDINGS } from '../../config';
import { store as dispatcherStore } from '../../store/DispatcherStore';

import { connect } from 'react-redux';
import { parseDate } from '../../helper/helper';

export const SnapshotsGalleryComponent = ({ images, preventPhotoAutoDownload}) => {
    const galleryRef = useRef();

    useEffect(() => {
        const downloadLatest = () => {
            if (!preventPhotoAutoDownload) {
                const links = galleryRef.current.querySelectorAll('a');
                if (links.length !== 0) {
                    links[links.length - 1].click();
                }
            }
        };

        downloadLatest();
    }, [images, preventPhotoAutoDownload]);
    
    return (
        <div className="snapshots__gallery" ref={galleryRef}>
            {images.map(image => {
                const ending = IMAGE_TYPE_ENDINGS[image.type];
                return (
                    <a
                        key={image.time}
                        download={`${IMAGE_NAME}_${dispatcherStore.userId}_${dispatcherStore.bystanderToken}_${image.time}.${ending}`}
                        href={`data:application/octet-stream;base64${image.image}`}>
                        <Download />
                        <img src={image.image} alt={`image_${image.time}`} />
                        <span>{parseDate(image.time)}</span>
                    </a>
                );
            })}
        </div>
    );
};

// PropTypes for this Component
SnapshotsGalleryComponent.propTypes = {
    images: PropTypes.array,
    preventPhotoAutoDownload: PropTypes.bool,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        images: state.session.images,
        preventPhotoAutoDownload: state.application.preventPhotoAutoDownload,
    };
};

// Connect Props and Dispatch to Component
export const SnapshotsGallery = connect(mapStateToProps)(
    SnapshotsGalleryComponent
);
