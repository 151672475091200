import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './ConferenceControls.scss';
import ArrowDown from '../Icons/ArrowDown';
import Contact from '../Icons/Contact';
import {
    activateScreenSharingDispatcherDispatch,
    closeConferenceUsersPanelDispatch,
    deactivateScreenSharingDispatcherDispatch,
    muteMicDispatcherDispatch,
    openConferenceUsersPanelDispatch,
    unmuteMicDispatcherDispatch,
} from '../../redux/actions/conferencing';
import { deactivateConferencingDispatch, muteAudioDispatch } from '../../redux/actions/application';
import { store } from '../../store/DispatcherStore';
import { connect } from 'react-redux';
import ConferenceUsersPanel from './ConferenceUsersPanel';
import Microphone from '../Icons/Microphone';
import MicrophoneMuted from '../Icons/MicrophoneMuted';
import { replaceText } from '../../helper/helper';
import { unpublishStreamAndRemoveFromRedux } from '../../helper/rtcFlowHandling';
import Pause from '../Icons/Pause';
import { Play } from '../Icons/Play';

const ConferenceControls = ({
    micIsMuted,
    screenSharingIsActive,
    conferencingIsActive,
    conferenceUsersPanelIsOpen,
    texts,
    audioStreamIsActive,
    dispatcherAudioStream,
    dispatcherStream,
    audioIsMuted,
}) => {
    const [controlPanelIsHidden, setControlPanelIsHidden] = useState(false);

    let renderUsersTimeout = useRef();

    let panelMinimizerClasses = `controlsMinimizer__panel 
        ${controlPanelIsHidden ? 'controlsMinimizer__panel--isCollapsed' : ''}
        ${conferencingIsActive ? '' : 'controlsMinimizer__panel--isHidden'}
        `;

    let controlPanelClasses = `conferenceControls__container ${controlPanelIsHidden ? 'conferenceControls__container--isHidden' : ''}`;

    let toggleIconClasses = `toggleIcon ${controlPanelIsHidden ? 'toggleIcon--isCollapsed' : ''}`;

    const toggleMicrophone = () => {
        if (!micIsMuted) {
            muteMicDispatcherDispatch();
        } else if (micIsMuted) {
            unmuteMicDispatcherDispatch();
        }
    };

    const toggleConferenceUsersPanel = () => {
        if (conferenceUsersPanelIsOpen) {
            closeConferenceUsersPanelDispatch();
            clearTimeout(renderUsersTimeout.current);
        } else if (!conferenceUsersPanelIsOpen) {
            const openPanelBeforeRenderingList = () => {
                openConferenceUsersPanelDispatch();
            };

            const renderUsersInList = async () => {
                await openPanelBeforeRenderingList();
                store.renderUserList();
            };

            renderUsersInList();
        }
    };

    const stopConferencing = () => {
        store.sendDispatcherLeftToConferenceUsers();
        if (screenSharingIsActive) {
            deactivateScreenSharingDispatcherDispatch();
        }
        closeConferenceUsersPanelDispatch();
        deactivateConferencingDispatch();
        if (!audioStreamIsActive) {
            if (!micIsMuted) muteMicDispatcherDispatch();
            if (!audioIsMuted) muteAudioDispatch();
            unpublishStreamAndRemoveFromRedux(dispatcherAudioStream, store);
        }
    };

    const toggleScreenSharing = () => {
        if (screenSharingIsActive) {
            deactivateScreenSharingDispatcherDispatch();
        } else {
            activateScreenSharingDispatcherDispatch();
        }
    };

    const toggleControlPanel = () => {
        if (!controlPanelIsHidden) {
            setControlPanelIsHidden(true);
        } else {
            setControlPanelIsHidden(false);
        }
    };

    let screenShareClasses = `controls ${screenSharingIsActive && !dispatcherStream ? 'screenshare__button--isDisabled' : ''}`;

    return (
        <React.Fragment>
            <div className="conferenceControls">
                <div className={controlPanelClasses}>
                    <div className="conferenceControls__container">
                        <div className="buttons__container">
                            <button className="btn btn--secondary" onClick={stopConferencing}>
                                {replaceText(texts, 'conferencing.controls.endSession')}
                            </button>
                            <div className={screenShareClasses} disabled onClick={toggleScreenSharing}>
                                {screenSharingIsActive ? (
                                    <div title={replaceText(texts, 'conferencing.controls.stopSharing')}>
                                        <Pause />
                                        <span>{replaceText(texts, 'conferencing.controls.shareScreen')}</span>
                                    </div>
                                ) : (
                                    <div title={replaceText(texts, 'conferencing.controls.startSharing')}>
                                        <Play />
                                        <span>{replaceText(texts, 'conferencing.controls.shareScreen')}</span>
                                    </div>
                                )}
                            </div>
                            <div className="controls" onClick={toggleMicrophone}>
                                {!micIsMuted ? (
                                    <>
                                        <div title={replaceText(texts, 'conferencing.controls.muteMic')}>
                                            <Microphone />
                                            <span>{replaceText(texts, 'conferencing.controls.mic')}</span>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div title={replaceText(texts, 'conferencing.controls.unmuteMic')}>
                                            <MicrophoneMuted />
                                            <span>{replaceText(texts, 'conferencing.controls.mic')}</span>
                                        </div>
                                    </>
                                )}
                            </div>

                            <div className="controls" disabled onClick={toggleConferenceUsersPanel}>
                                <div
                                    title={
                                        !conferenceUsersPanelIsOpen
                                            ? replaceText(texts, 'conferencing.controls.openPanel')
                                            : replaceText(texts, 'conferencing.controls.closePanel')
                                    }>
                                    <Contact />
                                    <span>{replaceText(texts, 'conferencing.controls.participants')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={panelMinimizerClasses} onClick={toggleControlPanel}>
                    <ArrowDown addClass={toggleIconClasses} />
                </div>
            </div>

            {conferenceUsersPanelIsOpen && <ConferenceUsersPanel />}
        </React.Fragment>
    );
};

ConferenceControls.propTypes = {
    screenSharingIsActive: PropTypes.bool,
    micIsMuted: PropTypes.bool,
    conferencingIsActive: PropTypes.bool,
    conferenceUsersPanelIsOpen: PropTypes.bool,
    texts: PropTypes.any,
    audioStreamIsActive: PropTypes.bool,
    dispatcherAudioStream: PropTypes.object,
    dispatcherStream: PropTypes.object,
    audioIsMuted: PropTypes.bool,
};

const mapStateToProps = state => {
    return {
        screenSharingIsActive: state.conferencing.screenSharingIsActive,
        micIsMuted: state.conferencing.micIsMuted,
        conferencingIsActive: state.application.conferencingIsActive,
        conferenceUsersPanelIsOpen: state.conferencing.conferenceUsersPanelIsOpen,
        texts: state.texts.texts,
        audioStreamIsActive: state.application.audioStreamIsActive,
        dispatcherAudioStream: state.streams.dispatcherAudioStream,
        dispatcherStream: state.streams.dispatcherStream,
        audioIsMuted: state.application.audioIsMuted,
    };
};

export default connect(mapStateToProps)(ConferenceControls);
