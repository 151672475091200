import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './Snapshots.scss';
import { store as dispatcherStore } from '../../store/DispatcherStore';
import { C_LOST, DISPLAY_ONLY_IN_SESSION, IMAGE_NAME, PHOTO_LIMIT } from '../../config';
import { errorLog } from '../../helper/logging';
import { createKpiLog, replaceText } from '../../helper/helper';
import { addSessionImageDispatch, dispatchChangeImageFormat } from '../../redux/actions/session';
import { activateHDSendDispatch, deactivateHDSendDispatch, deactivateSnapshotDispatch, dispatchCallerFileIsNotBusy } from '../../redux/actions/application';
import { SnapshotsGallery } from './SnapshotsGallery';
import Screenshot from '../Icons/Screenshot';
import HD from '../Icons/HD';
import { getSnapshotMimeType } from '../../api/backendApi';
import { addNotificationAndShowDispatch, hideAndRemoveNotificationsDispatch } from '../../redux/actions/notifications';
import NoPhoto from '../Icons/NoPhoto';
import ConnectionOverlay from '../Globals/ConnectionOverlay';
import { setHdPhotoDisclaimerNotClickedDispatch } from '../../redux/actions/disclaimers';

/**
 * Snapshots
 * Shows the snapshots taken and provides the button to take a snapshot.
 * Snapshots are extracted from a running video stream.
 * Snapshots are immediately downloaded.
 */

class Snapshots extends PureComponent {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            snapshots: [],
            hdButtonIsClicked: false,
            hdButtonIsDisabled: false,
            hdInitialActivation: false,
            hdSendButtonText: '',
            snapShotButtonText: '',
            isDisabled: true,
        };
    }

    handleContactMessage = e => {
        const message = JSON.parse(e.content);
        if (message && message.data === 'photoPermission') {
            if (this._isMounted) {
                this.setState({ photoPermission: message.permission });

                if (message.permission === false) {
                    addNotificationAndShowDispatch(replaceText(this.props.texts, 'snapshot.permission.denied'), 'error', DISPLAY_ONLY_IN_SESSION);
                }
            }
        }
    };

    snapShot = () => {
        if (!this.props.photoPermission) return;
        const currentImageFormat = this.props.imageFormat === 'PNG' ? 'image/png' : 'image/jpeg';
        dispatcherStore.callerStream
            .takeSnapshot({ outputMimeType: currentImageFormat })
            .then(snapshot => {
                addSessionImageDispatch({
                    image: snapshot,
                    time: new Date().getTime(),
                    type: currentImageFormat,
                });

                const fileName = `${IMAGE_NAME}_${dispatcherStore.userId}_${dispatcherStore.bystanderToken}_${
                    this.props.images[this.props.images.length - 1].time
                }.${this.props.imageFormat}`;

                const additionalStates = {
                    0: fileName,
                };

                createKpiLog('infoScreenshotTaken', '', additionalStates);
            })
            .catch(e => {
                errorLog({
                    message: 'apiRTC not a available - caller',
                    error: e,
                    eventId: 'APIRTC_SCRIPT_LOADING_CALLER',
                });
            });
    };

    allowHdPhoto = () => {
        this.setState({ hdButtonIsClicked: true, hdButtonIsDisabled: true });
        if (this.props.hdPhotoDisclaimerClicked === null || this.props.hdPhotoDisclaimerClicked === false) {
            addNotificationAndShowDispatch('warning.hdphoto.notice', 'warning', DISPLAY_ONLY_IN_SESSION);

            createKpiLog('infoHdPhotoDisclaimer', 'displayed');
            setTimeout(() => {
                if (this.props.currentNotifications) {
                    this.props.currentNotifications.map(notification => {
                        if (notification.message === 'warning.hdphoto.notice') {
                            setHdPhotoDisclaimerNotClickedDispatch();
                            createKpiLog('infoHdPhotoDisclaimer', 'timeout');
                            hideAndRemoveNotificationsDispatch(notification.type);
                        }
                        return null;
                    });
                }
            }, 8000);
        }
        deactivateHDSendDispatch();
        activateHDSendDispatch();
    };

    async componentDidMount() {
        this._isMounted = true;

        dispatchChangeImageFormat(await getSnapshotMimeType());
    }

    handleButtonsState() {
        const photoLimitReached = this.props.images.length >= PHOTO_LIMIT;

        const isNotConnected = !this.props.isConnected || this.props.connectionStatus === C_LOST;

        const isActiveAndNotPaused = this.props.snapshotIsActive && !this.props.snapshotPaused;

        switch (true) {
            case isActiveAndNotPaused && this.props.photoPermission === false && this.props.snapshotFeature && !photoLimitReached && !this.props.snapshotPaused:
                this.setState({
                    hdButtonIsDisabled: true || isNotConnected,
                    isDisabled: true || isNotConnected,
                    snapShotButtonText: 'snapshot.button.waiting',
                    hdSendButtonText: 'hdsend.button.waiting',
                });
                break;
            case isActiveAndNotPaused && this.props.photoPermission && !this.state.hdButtonIsClicked && !photoLimitReached:
                this.setState({
                    hdButtonIsDisabled: false || isNotConnected,
                    isDisabled: false || isNotConnected,
                    snapShotButtonText: 'snapshot.button.enabled',
                    hdSendButtonText: 'hdsend.button.enabled',
                });
                break;
            case isActiveAndNotPaused && this.props.photoPermission && this.state.hdButtonIsClicked && !photoLimitReached:
                this.setState({
                    hdButtonIsDisabled: true || isNotConnected,
                    isDisabled: false || isNotConnected,
                    snapShotButtonText: 'snapshot.button.enabled',
                    hdSendButtonText: 'hdsend.button.enabled',
                });
                break;
            case isActiveAndNotPaused && !this.props.photoPermission && !this.props.snapshotFeature && !photoLimitReached:
                this.setState({
                    hdButtonIsDisabled: false || isNotConnected,
                    isDisabled: false || isNotConnected,
                    snapShotButtonText: 'snapshot.button.enabled',
                    hdSendButtonText: 'hdsend.button.enabled',
                });
                break;
            case photoLimitReached:
                this.setState({
                    hdButtonIsDisabled: true || isNotConnected,
                    isDisabled: true || isNotConnected,
                    snapShotButtonText: 'snapshot.limit.reached',
                    hdSendButtonText: 'hdsend.limit.reached',
                });
                break;
            // case !this.props.snapshotDisclaimerAccepted:
            //     this.setState({
            //         hdButtonIsDisabled: true || isNotConnected,
            //         isDisabled: true || isNotConnected,
            //         snapShotButtonText: 'snapshot.button.enabled',
            //         hdSendButtonText: 'hdsend.button.enabled',
            //     });
            //     break;
            default:
                this.setState({
                    hdButtonIsDisabled: true || isNotConnected,
                    isDisabled: true || isNotConnected,
                    hdButtonIsClicked: false,
                    snapShotButtonText: 'snapshot.button.disabled',
                    hdSendButtonText: 'hdsend.button.disabled',
                });
                break;
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isConnected && !this.props.isConnected) {
            this.setState({ photoPermission: false });
            deactivateSnapshotDispatch();
        }
        if (prevProps.callerFileIsBusy && !this.props.callerFileIsBusy) {
            this.setState({
                hdButtonIsClicked: false,
                hdButtonIsDisabled: false,
            });
        }
        if (!this.props.videoIsActive) {
            dispatchCallerFileIsNotBusy();
            deactivateHDSendDispatch();
        }

        this.handleButtonsState();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const showHint = this.props.isConnected && this.props.hdSendIsActive;
        return (
            <div className="snapshots">
                <div className="snapshots connectionOverlay">
                    <ConnectionOverlay />
                </div>
                <div className="snapshots__button">
                    {this.props.snapshotFeature && (
                        <div>
                            <button disabled={this.state.isDisabled} onClick={this.snapShot} className="btn btn--primary">
                                <Screenshot />
                                {replaceText(this.props.texts, this.state.snapShotButtonText)}
                            </button>
                        </div>
                    )}
                    {this.props.hdSendFeature && (
                        <div>
                            <button disabled={this.state.hdButtonIsDisabled} onClick={this.allowHdPhoto} className="btn btn--primary">
                                <HD />
                                {replaceText(this.props.texts, this.state.hdSendButtonText)}
                            </button>
                            {showHint && (
                                <div className="snapshots__hint">
                                    {this.props.callerFileIsBusy ? (
                                        <>{replaceText(this.props.texts, 'hdsend.waiting.busy')}</>
                                    ) : (
                                        <>{replaceText(this.props.texts, 'hdsend.waiting.caller')}</>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
                {this.props.snapshotIsActive ? (
                    <div className="snapshotsPlaceholder snapshotsPlaceholder--hidden">
                        <NoPhoto />
                    </div>
                ) : (
                    <div className={`snapshotsPlaceholder ${this.props.images.length > 0 && 'snapshotsPlaceholder--hidden'}`}>
                        <NoPhoto />
                    </div>
                )}
                <SnapshotsGallery />
            </div>
        );
    }
}

// PropTypes for this Component
Snapshots.propTypes = {
    snapshotIsActive: PropTypes.bool,
    snapshotPaused: PropTypes.bool,
    imageFormat: PropTypes.string,
    hdSendIsActive: PropTypes.bool,
    isConnected: PropTypes.bool,
    connectionStatus: PropTypes.string,
    texts: PropTypes.any,
    hdSendFeature: PropTypes.bool,
    snapshotFeature: PropTypes.bool,
    callerFileIsBusy: PropTypes.bool,
    images: PropTypes.arrayOf(PropTypes.object),
    photoPermission: PropTypes.bool,
    videoIsActive: PropTypes.bool,
    hdPhotoDisclaimerClicked: PropTypes.bool,
    currentNotifications: PropTypes.array,
};

const mapStateToProps = state => {
    return {
        snapshotIsActive: state.application.snapshotIsActive,
        hdSendIsActive: state.application.hdSendIsActive,
        snapshotPaused: state.application.snapshotPaused,
        imageFormat: state.session.imageFormat,
        isConnected: state.connection.isConnected,
        connectionStatus: state.connection.status,
        texts: state.texts.texts,
        hdSendFeature: state.features.hdSendFeature,
        snapshotFeature: state.features.snapshotFeature,
        callerFileIsBusy: state.application.callerFileIsBusy,
        images: state.session.images,
        photoPermission: state.session.photoPermission,
        videoIsActive: state.application.videoIsActive,
        hdPhotoDisclaimerClicked: state.disclaimers.hdPhotoDisclaimerClicked,
        currentNotifications: state.notifications.currentNotifications,
    };
};

export default connect(mapStateToProps)(Snapshots);
