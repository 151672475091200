import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { PHONE_COUNTRIES } from '../../config';
import { replaceText } from '../../helper/helper';

import './PhoneCountry.scss';

export const PhoneCountryComponent = ({ phone, texts }) => {
    const normalizedPhone = phone.replace('+', '00');

    const filteredCountry = PHONE_COUNTRIES.filter(c => normalizedPhone.slice(0, 5).indexOf(c.prefix) !== -1)[0];

    return (
        <div className="phone-country">
            {filteredCountry && (
                <>
                    <span>
                        <img src={`${process.env.PUBLIC_URL}/flags/${filteredCountry.country}.svg`} alt="country" />
                    </span>
                    <span>{filteredCountry.prefix}</span>
                    <span>{replaceText(texts, filteredCountry.country)}</span>
                </>
            )}
        </div>
    );
};

// PropTypes for this Component
PhoneCountryComponent.propTypes = {
    texts: PropTypes.any,
    phone: PropTypes.string,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
    };
};

// Connect Props and Dispatch to Component
export const PhoneCountry = connect(mapStateToProps)(PhoneCountryComponent);
