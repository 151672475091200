import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { store } from '../../store/DispatcherStore';

import './InformationPanel.scss';
import { createKpiLog, replaceText } from '../../helper/helper';
import GeoSMS from './GeoSMS';
import { addSessionGPSDispatch } from '../../redux/actions/session';

/**
 * GeoPanel
 * Contains the information of the geo location from the bystander.
 * Information is transmitted via the established webRTC connection.
 *
 * @component GeoSMS - Geo SMS Overlay
 */
class GeoPanel extends PureComponent {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            coords: false,
            latitude: '_______',
            longtitude: '_______',
            accurary: '_',
            smsOpen: false,
        };

        store.connectedSession.removeListener('contactMessage', this.handleContactMessage);
        store.connectedSession.on('contactMessage', this.handleContactMessage);
    }

    handleContactMessage = e => {
        //Display message in UI
        const message = JSON.parse(e.content);
        if (message && message.data === 'coords') {
            if (this._isMounted) {
                this.setState({
                    coords: true,
                    longtitude: message.longitude,
                    latitude: message.latitude,
                    accurary: message.accuracy,
                });

                addSessionGPSDispatch({
                    long: message.longitude,
                    lat: message.latitude,
                });

                const additionalStates = {
                    0: message.longitude,
                    1: message.latitude,
                    2: message.accuracy,
                };

                createKpiLog('infoGpsCoordinatesReceived', '', additionalStates);
            }
        }
    };

    openSms = () => {
        this.setState({ smsOpen: true });
    };

    closeSms = () => {
        this.setState({ smsOpen: false });
    };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <div>
                <ul className="informationPanel">
                    <li className="informationPanel__item" id="gps_lat">
                        <strong>
                            <span>{replaceText(this.props.texts, 'info.lat')}</span>
                        </strong>
                        {this.state.latitude}
                    </li>
                    <li className="informationPanel__item" id="gps_long">
                        <strong>
                            <span>{replaceText(this.props.texts, 'info.long')}</span>
                        </strong>
                        {this.state.longtitude}
                    </li>
                    <li className="informationPanel__item" id="gps_acc">
                        <strong>
                            <span>{replaceText(this.props.texts, 'info.acc')}</span>
                        </strong>
                        {Math.round(this.state.accurary)} m
                    </li>

                    {this.props.geoSMSFeature && (
                        <li className="informationPanel__item">
                            <button onClick={this.openSms} disabled={!this.state.coords} className="btn btn--primary btn--full">
                                {replaceText(this.props.texts, 'geoSMS.send')}
                            </button>
                        </li>
                    )}
                </ul>
                {this.props.geoSMSFeature && (
                    <GeoSMS open={this.state.smsOpen} closeHandler={this.closeSms} lat={this.state.latitude + ''} long={this.state.longtitude + ''} />
                )}
            </div>
        );
    }
}

// PropTypes for this Component
GeoPanel.propTypes = {
    texts: PropTypes.any,
    geoSMSFeature: PropTypes.bool,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
        geoSMSFeature: state.features.geoSMSFeature,
    };
};

export default connect(mapStateToProps)(GeoPanel);
