import React, { useEffect, useState } from 'react';
import { Image } from '../Icons/Image';
import { Document } from '../Icons/Document';
import { Presentation } from '../Icons/Presentation';
import { Table } from '../Icons/Table';
import { Video } from '../Icons/Video';
import { Cancel } from '../Icons/Cancel';
import { removePreviewFileDispatch } from '../../redux/actions/files';
import Download from '../Icons/Download';
import { SoundFile } from '../Icons/SoundFile';
import { MAX_FILE_NAME_LENGTH } from '../../config';

interface ChatFileProps {
    file: any;
    preview: boolean;
    download: boolean;
    clickHandler;
}

const ChatFile: React.FC<ChatFileProps> = ({ file, preview, download, clickHandler }) => {
    const [fileType, setFileType] = useState('');

    const handleClick = download ? clickHandler : preview ? removePreviewFileDispatch : null;

    useEffect(() => {
        const checkFileType = (file: any, category: any) => {
            return Object.keys(category).some(mimeType => mimeType === file.type);
        };

        const imageType = {
            'image/jpg': 'jpeg',
            'image/jpeg': 'jpeg',
            'image/png': 'png',
            'image/gif': 'gif',
            'image/svg+xml': 'svg',
            'image/tiff': 'tiff',
            'image/webp': 'webp',
        };

        const documentType = {
            'application/pdf': 'pdf',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
            'application/msword': 'doc',
            'text/plain': 'txt',
            'text/rtf': 'rtf',
            'application/vnd.oasis.opendocument.text': 'odt',
        };

        const presentationType = {
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
            'application/vnd.ms-powerpoint': 'ppt',
            'application/vnd.oasis.opendocument.presentation': 'odp',
        };

        const tableType = {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
            'application/vnd.ms-excel': 'xls',
            'text/csv': 'csv',
            'application/vnd.oasis.opendocument.spreadsheet': 'ods',
            'application/json': 'json',
            'application/xml': 'xml',
        };

        const videoType = {
            'video/mp4': 'mp4',
            'video/avi': 'avi',
            'video/quicktime': 'mov',
            'video/x-ms-wmv': 'wmv',
            'video/x-matroska': 'mkv',
            'video/mpeg': 'mpeg',
            'video/ogg': 'ogv',
            'video/webm': 'webm',
        };

        const audioType = {
            'audio/mpeg': 'mp3',
            'audio/wav': 'wav',
            'audio/ogg': 'ogg',
        };

        switch (true) {
            case checkFileType(file, imageType):
                setFileType('image');
                break;
            case checkFileType(file, documentType):
                setFileType('document');
                break;
            case checkFileType(file, presentationType):
                setFileType('presentation');
                break;
            case checkFileType(file, tableType):
                setFileType('table');
                break;
            case checkFileType(file, videoType):
                setFileType('video');
                break;
            case checkFileType(file, audioType):
                setFileType('audio');
                break;
            default:
                setFileType('unknown');
        }
    }, [file]);

    return (
        <div className="chat-file-preview flex-column">
            <div className="flex-row">
                <div className="chat-file-icons" onClick={handleClick}>
                    {renderFileTypeIcon(fileType)}
                    {preview ? <Cancel /> : ''}
                    {download ? <Download /> : ''}
                </div>
                <div className="chat-file-info">
                    {/* truncate file name to fit within the bondary of the chat bubble */}
                    <span>{file.name.length > MAX_FILE_NAME_LENGTH ? file.name.substring(0, MAX_FILE_NAME_LENGTH) : file.name}</span>
                    <span>{file.name.length > MAX_FILE_NAME_LENGTH ? file.name.substring(MAX_FILE_NAME_LENGTH, (MAX_FILE_NAME_LENGTH * 2)) : ''}
                    {file.name.length > (MAX_FILE_NAME_LENGTH * 2) ? '...' : ''}
                    </span>
                    <span>{file.size}</span>
                </div>
            </div>
        </div>
    );
};

export default ChatFile;

export const renderFileTypeIcon = (fileType: string) => {
    switch (fileType) {
        case 'image':
            return <Image />;
        case 'document':
            return <Document />;
        case 'presentation':
            return <Presentation />;
        case 'table':
            return <Table />;
        case 'video':
            return <Video />;
        case 'audio':
            return <SoundFile />;

        default:
            return null;
    }
};
