import React from 'react';

export default function ExitCross() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12.021" height="12.021">
            <g data-name="Group 4985" strokeLinecap="round" strokeWidth="2.5">
                <path data-name="Line 171" d="m1.767 1.767 8.486 8.486" />
                <path data-name="Line 199" d="m10.253 1.767-8.486 8.486" />
            </g>
        </svg>
    );
}
