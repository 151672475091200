import React from 'react';
import { connect } from 'react-redux';
import { replaceText } from '../../helper/helper';
import PropTypes from 'prop-types';
import './ConferenceUsersPanel.scss';
import ExitCross from '../Icons/ExitCross';
import Microphone from '../Icons/Microphone';
import MicrophoneMuted from '../Icons/MicrophoneMuted';
import { closeConferenceUsersPanelDispatch, openConferenceUsersPanelDispatch } from '../../redux/actions/conferencing';
import { getURLParams } from '../../helper/rtcFlowHandling';
import { store as dispatcherStore } from '../../store/DispatcherStore';
import KickUser from '../Icons/KickUser';

const ConferenceUsersPanel = ({ texts, conferenceUserStreams, conferenceUsersPanelIsOpen, isDispatcher, dispatcherAudioStream }) => {
    const { contactId } = getURLParams();
    const togglePanel = () => {
        if (conferenceUsersPanelIsOpen) {
            closeConferenceUsersPanelDispatch();
        } else {
            openConferenceUsersPanelDispatch();
        }
    };

    const muteUserMicrophone = conferenceUsername => {
        if (isDispatcher) {
            var contacts = dispatcherStore.connectedConversation.getContacts();
            var keys = Object.keys(contacts);

            for (const element of keys) {
                if (contacts[element].getUsername() === conferenceUsername) {
                    dispatcherStore.sendMuteMicrophone(contacts[element]);
                }
            }
        }
    };

    const muteAllConferenceMics = () => {
        if (isDispatcher) {
            dispatcherStore.sendMuteAllMicrophones();
        }
    };

    const kickConferenceUser = conferenceUser => {
        const message = {
            data: 'dispatcherLeft',
        };

        dispatcherStore.sendMessageToConferenceUser(message, conferenceUser);
    };

    let userListClasses = `userList__container ${!isDispatcher ? 'userList__container--isNotDispatcher' : ''}`;

    let panelFooterClasses = `panelFooter ${!isDispatcher ? 'panelFooter--isNotDispatcher' : ''}`;

    return (
        <div className="conferenceUsers">
            <div className="conferenceUsers__panel">
                <div className="panelHeader" onClick={togglePanel}>
                    {isDispatcher ? (
                        <span>
                            {replaceText(texts, 'conference.usersPanel.title').toUpperCase()}
                            {' ('}
                            {conferenceUserStreams.length}
                            {')'}
                        </span>
                    ) : (
                        <span>
                            {replaceText(texts, 'conference.usersPanel.title').toUpperCase()}
                            {' ('}
                            {/* For conference users, a 1 must be added to represent the dispatcher as a conference user */}
                            {Number(conferenceUserStreams.length) + 1}
                            {')'}
                        </span>
                    )}

                    <div>
                        <ExitCross />
                    </div>
                </div>
                <ul className={userListClasses}>
                    {/* If component is being called by a conference user, add dispatcher stream info */}
                    {!isDispatcher && dispatcherAudioStream && (
                        <li className="user">
                            <span>{dispatcherAudioStream.contact.userData.username}</span>
                            <span>{dispatcherAudioStream.callAudioMuted ? <MicrophoneMuted /> : <Microphone />}</span>
                        </li>
                    )}
                    {conferenceUserStreams.map(
                        (conferenceUser, streamKey) =>
                            conferenceUser.contact.userData.username !== contactId && (
                                <li className="user" key={streamKey}>
                                    <div>
                                        <span>{conferenceUser.contact.userData.username}</span>
                                        {/* If dispatcher, allow kick user option */}
                                        {isDispatcher && (
                                            <span
                                                onClick={() => {
                                                    kickConferenceUser(conferenceUser.contact);
                                                }}>
                                                <KickUser />
                                            </span>
                                        )}
                                    </div>
                                    <span onClick={() => muteUserMicrophone(conferenceUser.contact.userData.username)}>
                                        {conferenceUser.callAudioMuted ? <MicrophoneMuted /> : <Microphone />}
                                    </span>
                                </li>
                            )
                    )}
                </ul>
                <div className={panelFooterClasses}>
                    <span onClick={muteAllConferenceMics}>{replaceText(texts, 'conference.usersPanel.muteAll')}</span>
                </div>
            </div>
        </div>
    );
};

// PropTypes for this Component
ConferenceUsersPanel.propTypes = {
    texts: PropTypes.object,
    conferenceUserStreams: PropTypes.array,
    conferenceUsersPanelIsOpen: PropTypes.bool,
    streamInfo: PropTypes.array,
    isDispatcher: PropTypes.bool,
    dispatcherAudioStream: PropTypes.object,
};

const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
        conferenceUserStreams: state.streams.conferenceUserStreams,
        conferenceUsersPanelIsOpen: state.conferencing.conferenceUsersPanelIsOpen,
        streamInfo: state.conferencing.streamInfo, // This needs to stay in component for properly updating microphone states
        isDispatcher: state.conferencing.isDispatcher,
        dispatcherAudioStream: state.streams.dispatcherAudioStream,
    };
};

export default connect(mapStateToProps)(ConferenceUsersPanel);
