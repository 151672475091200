import React from 'react';

export default function NoRecording() {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200">
                <path
                    fill="#e6e7e8"
                    d="M166.76 95.62c1.56 0 2.82-1.26 2.82-2.82V71.65c0-1.56-1.26-2.82-2.82-2.82-1.56 0-2.82 1.26-2.82 2.82V92.8c0 1.56 1.26 2.82 2.82 2.82ZM156.86 92.96c1.56 0 2.82-1.26 2.82-2.82v-14.1c0-1.56-1.26-2.82-2.82-2.82-1.56 0-2.82 1.26-2.82 2.82v14.1c0 1.56 1.26 2.82 2.82 2.82ZM146.95 102.6c1.56 0 2.82-1.26 2.82-2.82V64.54c0-1.56-1.26-2.82-2.82-2.82-1.56 0-2.82 1.26-2.82 2.82v35.24c0 1.56 1.26 2.82 2.82 2.82ZM137.05 95.62c1.56 0 2.82-1.26 2.82-2.82V71.65c0-1.56-1.26-2.82-2.82-2.82s-2.82 1.26-2.82 2.82V92.8c0 1.56 1.26 2.82 2.82 2.82ZM176.61 90.15c1.56 0 2.82-1.26 2.82-2.82v-8.46c0-1.56-1.26-2.82-2.82-2.82s-2.82 1.26-2.82 2.82v8.46c0 1.56 1.26 2.82 2.82 2.82ZM128.18 89.34c1.56 0 2.82-1.26 2.82-2.82v-8.46c0-1.56-1.26-2.82-2.82-2.82-1.56 0-2.82 1.26-2.82 2.82v8.46c0 1.56 1.26 2.82 2.82 2.82ZM51.44 141.74c-1.71 0-3.37-.56-4.74-1.63a7.719 7.719 0 0 1-2.93-5.15c-.02-.17-.03-.33-.03-.5v-33.23c0-.17.01-.33.03-.5.14-1.13.54-2.23 1.15-3.19 2.27-3.56 6.98-4.64 10.56-2.46l30.04 16.48c1.42.7 2.57 1.88 3.25 3.32.79 1.69.87 3.59.23 5.35a6.946 6.946 0 0 1-3.47 3.87l-30.04 16.51c-.93.57-1.99.94-3.07 1.07-.33.04-.65.06-.98.06Zm.3-39.59v31.39l28.57-15.71-28.57-15.68ZM171.17 175.17c-1.02 0-2.05-.39-2.83-1.17L26.01 31.66a4.008 4.008 0 0 1 0-5.66 4.008 4.008 0 0 1 5.66 0L174 168.33a4.008 4.008 0 0 1 0 5.66c-.78.78-1.8 1.17-2.83 1.17Z"
                />
                <path
                    fill="#e6e7e8"
                    d="M174.28 38.51h-79.3c-9.49 0-17.22 7.72-17.22 17.22V74.2H25.72c-9.49 0-17.22 7.72-17.22 17.22v52.87c0 9.49 7.72 17.22 17.22 17.22h79.3c9.49 0 17.22-7.72 17.22-17.22v-18.47h52.05c9.49 0 17.22-7.72 17.22-17.22V55.73c0-9.49-7.72-17.22-17.22-17.22Zm-60.05 105.77c0 5.08-4.13 9.22-9.22 9.22H25.72c-5.08 0-9.22-4.13-9.22-9.22V91.41c0-5.08 4.13-9.22 9.22-9.22h79.3c5.08 0 9.22 4.13 9.22 9.22v52.87Zm69.27-35.68c0 5.08-4.13 9.22-9.22 9.22h-52.05v-26.4c0-1.94-.34-3.81-.93-5.55V70.98c0-1.56-1.13-2.82-2.53-2.82s-2.53 1.26-2.53 2.82v7.41a17.088 17.088 0 0 0-4.54-2.84V64.54c0-1.56-1.13-2.82-2.53-2.82s-2.53 1.26-2.53 2.82v9.74c-.54-.05-1.08-.08-1.64-.08h-2.18v-2.54c0-1.56-1.13-2.82-2.53-2.82s-2.53 1.26-2.53 2.82v2.54H85.74V55.73c0-5.08 4.13-9.22 9.22-9.22h79.3c5.08 0 9.22 4.13 9.22 9.22v52.87Z"
                />
            </svg>
        </div>
    );
}
