import React from 'react';

function NoPhoto() {
    return (
        // <svg viewBox="0 0 144 185" xmlns="http://www.w3.org/2000/svg">
        //     <g fill="#e6e7e8">
        //         <path d="M71.996 171.775c-12.404-11.183-54.129-52.046-54.129-95.671 0-29.846 24.287-54.133 54.133-54.133s54.133 24.287 54.133 54.133c0 43.554-41.741 84.483-54.137 95.671zM72 11.146c-35.821 0-64.963 29.137-64.963 64.962 0 56.05 59.021 104.984 61.538 107.042.992.812 2.208 1.221 3.425 1.221s2.433-.409 3.425-1.221c2.517-2.058 61.538-50.992 61.538-107.042 0-35.825-29.142-64.962-64.963-64.962z" />
        //         <path d="M72 97.758c-11.942 0-21.654-9.712-21.654-21.65 0-11.945 9.712-21.654 21.654-21.654s21.654 9.709 21.654 21.654c0 11.942-9.712 21.65-21.654 21.65zm0-54.133c-17.913 0-32.479 14.571-32.479 32.483 0 17.909 14.566 32.475 32.479 32.475s32.479-14.566 32.479-32.475c0-17.912-14.566-32.483-32.479-32.483z" />
        //         <path d="M.033 6.542l137.825 148.512 6.109-5.671L6.142.871" />
        //     </g>
        // </svg>

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.314 141.314">
            <g>
                <g>
                    <g>
                        <path
                            fill="none"
                            stroke="#e6e7e8"
                            strokeWidth="8"
                            d="M120.378 33.652H102.52l-7.05-7.049a11.734 11.734 0 0 0-8.3-3.446H63.2a11.735 11.735 0 0 0-8.3 3.446l-6.893 7.049H30.149a10.877 10.877 0 0 0-11.279 10.5v62.655a10.878 10.878 0 0 0 11.279 10.5h90.23a10.878 10.878 0 0 0 11.279-10.5V44.148a10.877 10.877 0 0 0-11.279-10.5"
                            data-name="Path 3027"
                        />
                    </g>
                    <g
                        fill="none"
                        stroke="#e6e7e8"
                        strokeWidth="8"
                        transform="translate(47.869 47.157)">
                        <circle cx="27.5" cy="27.5" r="27.5" stroke="none" />
                        <circle cx="27.5" cy="27.5" r="23.5" />
                    </g>
                </g>
                <path
                    fill="none"
                    stroke="#e6e7e8"
                    strokeLinecap="round"
                    strokeWidth="8"
                    d="m135.657 135.657-130-130"
                />
            </g>
        </svg>
    );
}

export default NoPhoto;
