import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import './StreamRecordingsGallery.scss'
import Download from '../Icons/Download';
import Loading from '../Icons/LoadingSpinner';
import { parseDate, replaceText } from '../../helper/helper';
import { renderToString } from 'react-dom/server';
import Audio from '../Icons/Audio';
import NoRecording from '../Icons/NoRecording';

interface RecordingInfo {
  recording: {
    mediaId: string;
    mediaURL: string;
    recordedFileName: string;
  }
  thumbnail: string;
  created: string;
  status: string;
}

export const StreamRecordingsGallery: React.FC<ConnectedProps<typeof connector>> = ({ recordings, texts }) => {
  const [imageUrl, setImageUrl] = useState('');

  const handleDownload = (recordingInfo: RecordingInfo) => {
      fetch(recordingInfo.recording.mediaURL)
        .then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            let filename = recordingInfo.recording.recordedFileName;
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        });
    }

    useEffect(() => {
      const svgString = renderToString(<Audio />);
      const encodedSvgString = encodeURIComponent(svgString);
      const svgUrl = `data:image/svg+xml;charset=utf-8,${encodedSvgString}`;
      setImageUrl(svgUrl);
  }, []);

    return (
        <div className='recordings'>
          {recordings.length === 0 ? <NoRecording /> : <div className='recordings__gallery'>
            {recordings.map(recordingInfo => {       
                return <button 
                key={recordingInfo.recording.mediaId && recordingInfo.recording.mediaId}
                onClick={() => 
                  recordingInfo.status === 'available' && handleDownload(recordingInfo)
                }
                >
                  {recordingInfo.status === 'available' && (<Download/>)}
                  {recordingInfo.thumbnail === "data:," && <img src={imageUrl} alt='' />}
                  {recordingInfo.thumbnail && recordingInfo.thumbnail !== "data:," && <img src={recordingInfo.thumbnail} alt='' />}
                {recordingInfo.status === 'started' && (<div><span>{replaceText(texts, 'stream.recording.started')}</span><Loading /></div>)}
                {recordingInfo.status === 'stopped' && (<div><span>{replaceText(texts, 'stream.recording.stopped')}</span><Loading /></div>)}
                <span>{recordingInfo.status === 'available' && parseDate(Number(recordingInfo.created))}</span>
                </button>;
            })}
            </div>}
        </div>
    );
};

// Map Redux State To Props
const mapStateToProps = (state: any) => {
    return {
        recordings: state.session.recordings,
        streamRecordingIsActive: state.application.streamRecordingIsActive,
        texts: state.texts.texts
    };
};

const connector = connect(mapStateToProps);
export default connector(StreamRecordingsGallery);
