import React from 'react';
import PropTypes from 'prop-types';
import parse from 'platform';
import { connect } from 'react-redux';

import { store } from '../../store/DispatcherStore';

import Help from '../Icons/Help';

import { HELPDESK_LINK, HELPDESK_OS, HELPDESK_OS_VERSIONS } from '../../config';

import './InformationPanel.scss';

import { setCallerLanguageDispatch } from '../../redux/actions/texts';
import { replaceText, getHelpdeskLanguage, createKpiLog } from '../../helper/helper';

/**
 * SystemPanel
 * Contains all System Information from the bystander.
 * Infos get transmitted via the established webRTC Connection.
 * Infos: operating system and version, browser name and version, battery left, language, connection quality
 */

class SystemPanel extends React.Component {
    _isMounted = false;
    _kpiSent = {
        battery: false,
        os: false,
        network: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            operationSystem: 'info.notAvailable',
            operationgSystemVersion: 'info.notAvailable',
            operatingSystemRaw: 'info.notAvailable',
            browserName: 'info.notAvailable',
            browserVersion: 'info.notAvailable',
            download: 'info.notAvailable',
            upload: 'info.notAvailable',
            battery: 'info.notAvailable',
            language: 'notAvailable',
        };

        store.connectedSession.removeListener('contactMessage', this.handleContactMessage);
        store.connectedSession.on('contactMessage', this.handleContactMessage);
    }

    handleContactMessage = e => {
        //Display message in UI
        const message = JSON.parse(e.content);
        if (message && message.data === 'system') {
            const info = parse.parse(message.browserVersion1b);
            if (this._isMounted) {
                this.setState({
                    operatingSystemRaw: message.browserVersion1a,
                    operationSystem: info.os.toString(),
                    browserName: info.name,
                    browserVersion: info.version,
                });

                if (!this._kpiSent.os) {
                    this._kpiSent.os = true;

                    const additionalStates = {
                        0: info.os.toString(),
                        1: info.name,
                        2: info.version,
                    };

                    createKpiLog('infoSystem', '', additionalStates);
                }
            }
        }
        if (message && message.data === 'networkInfo') {
            if (this._isMounted) {
                this.setState({
                    upload: message.upload.rating,
                    download: message.download.rating,
                });
                if (!this._kpiSent.network) {
                    this._kpiSent.network = true;

                    const additionalStates = {
                        0: message.upload.rating,
                        1: message.download.rating,
                    };

                    createKpiLog('infoNetwork', '', additionalStates);
                }
            }
        }
        if (message && message.data === 'battery') {
            if (this._isMounted) {
                this.setState({
                    battery: Math.round(message.level * 100) + '%',
                });

                if (!this._kpiSent.battery) {
                    this._kpiSent.battery = true;

                    const additionalStates = {
                        0: Math.round(message.level * 100) + '%',
                    };

                    createKpiLog('infoBattery', '', additionalStates);
                }
            }
        }
        if (message && message.data === 'updateCallerLanguage') {
            if (this._isMounted) {
                setCallerLanguageDispatch(message.language);
                this.setState({
                    language: message.language,
                });
            }
        }

        store.addCloseCallCallback(() => {
            if (this._isMounted) {
                this.setInitialState();
            }
        });
    };

    calculateOSLink() {
        const system = this.state.operationSystem;
        const osName = Object.keys(HELPDESK_OS).filter(osType => {
            return HELPDESK_OS[osType].some(os => system.toLowerCase().indexOf(os) !== -1);
        });

        if (osName.length === 0) {
            return HELPDESK_LINK;
        }

        const osVersion = HELPDESK_OS_VERSIONS[osName].filter(
            version =>
                // android
                system.toLowerCase().indexOf(version.replace('-', '.')) !== -1 ||
                // ios
                system.toLowerCase().indexOf(version.replace('-', '_')) !== -1
        );

        if (osName.length > 0) {
            if (osVersion.length > 0) {
                return `${HELPDESK_LINK}/${osName[0]}/${osName[0]}-${osVersion[0]}`;
            }
            return `${HELPDESK_LINK}/${osName[0]}`;
        }
        return HELPDESK_LINK;
    }

    setInitialState() {
        this.setState({
            operationSystem: 'info.notAvailable',
            operationgSystemVersion: 'info.notAvailable',
            operatingSystemRaw: 'info.notAvailable',
            browserName: 'info.notAvailable',
            browserVersion: 'info.notAvailable',
            download: 'info.notAvailable',
            upload: 'info.notAvailable',
            battery: 'info.notAvailable',
            language: 'notAvailable',
        });
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <ul className="informationPanel">
                <li className="informationPanel__item">
                    <div className="itemWrapper">
                        <span>{replaceText(this.props.texts, 'info.os')}</span>
                        <a href={this.calculateOSLink() + getHelpdeskLanguage(this.props.language)} target="_blank" rel="noopener noreferrer">
                            <Help />
                        </a>
                    </div>

                    {replaceText(this.props.texts, this.state.operationSystem)}
                </li>
                <li className="informationPanel__item">
                    <div className="itemWrapper">
                        <span>{replaceText(this.props.texts, 'info.browser')}</span>
                    </div>
                    {replaceText(this.props.texts, this.state.browserName)} / {replaceText(this.props.texts, this.state.browserVersion)}
                </li>
                <li className="informationPanel__item">
                    <strong>
                        <span>{replaceText(this.props.texts, 'info.language')}</span>
                    </strong>
                    {replaceText(this.props.texts, `lang.${this.state.language}`)}
                </li>
                <li className="informationPanel__item">
                    <strong>
                        <span>{replaceText(this.props.texts, 'info.battery')}</span>
                    </strong>
                    {replaceText(this.props.texts, this.state.battery)}
                </li>
                <li className="informationPanel__item">
                    <strong>
                        <span>{replaceText(this.props.texts, 'info.upload')}</span>
                    </strong>
                    {replaceText(this.props.texts, this.state.upload)}
                </li>
            </ul>
        );
    }
}

SystemPanel.propTypes = {
    texts: PropTypes.any,
    language: PropTypes.string,
};

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        texts: state.texts.texts,
        language: state.texts.language,
    };
};

// Connect Props and Dispatch to Component
export default connect(mapStateToProps)(SystemPanel);
