import React from 'react';
import PropTypes from 'prop-types';
// import { replaceText } from '../../helper/helper';
import './AudioPanel.scss';
import { connect } from 'react-redux';
import Speaker from '../Icons/Speaker';
import { muteMicDispatcherDispatch, unmuteMicDispatcherDispatch } from '../../redux/actions/conferencing';
import { muteAudioDispatch, muteCallerMicrophoneDispatcherDispatch, unmuteAudioDispatch } from '../../redux/actions/application';
import SpeakerMuted from '../Icons/SpeakerMuted';
import Microphone from '../Icons/Microphone';
import MicrophoneMuted from '../Icons/MicrophoneMuted';
import { store as dispatcherStore } from '../../store/DispatcherStore';
import { replaceText } from '../../helper/helper';

export const AudioPanel = ({ micIsMuted, audioStreamIsActive, audioIsMuted, callerMicIsMuted, texts }) => {
    const toggleMicrophone = () => {
        if (!micIsMuted) {
            muteMicDispatcherDispatch();
        } else if (micIsMuted) {
            unmuteMicDispatcherDispatch();
        }
    };

    const toggleAudio = () => {
        if (audioIsMuted) {
            unmuteAudioDispatch();
        } else {
            muteAudioDispatch();
        }
    };

    const toggleCallerMicrophone = () => {
        if (!callerMicIsMuted) {
            muteCallerMicrophoneDispatcherDispatch();
            dispatcherStore.toggleMicrophone(true);
        }
    };

    return (
        <div className="audioPanel">
            {audioStreamIsActive ? (
                <>
                    <div className="caller-controls">
                        <span>{replaceText(texts, 'audio.caller.label')}</span>
                        <div onClick={toggleCallerMicrophone}>{!callerMicIsMuted ? <Microphone /> : <MicrophoneMuted />}</div>
                    </div>
                    <div className="dispatcher-controls">
                        <span>{replaceText(texts, 'audio.dispatcher.label')}</span>
                        <div className="dispatcher-controls__container">
                            <div className="speakers" onClick={toggleAudio}>
                                {!audioIsMuted ? <Speaker /> : <SpeakerMuted />}
                            </div>
                            <div onClick={toggleMicrophone}>{!micIsMuted ? <Microphone /> : <MicrophoneMuted />}</div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="audioPanel--isDisabled">
                    <div className="caller-controls">
                        <span>{replaceText(texts, 'audio.caller.label')}</span>
                        <div>
                            <Microphone />
                        </div>
                    </div>
                    <div className="dispatcher-controls">
                        <span>{replaceText(texts, 'audio.dispatcher.label')}</span>
                        <div className="dispatcher-controls__container">
                            <div className="speakers">{!audioIsMuted ? <Speaker /> : <SpeakerMuted />}</div>
                            <div>{!micIsMuted ? <Microphone /> : <MicrophoneMuted />}</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

AudioPanel.propTypes = {
    micIsMuted: PropTypes.bool,
    audioStreamIsActive: PropTypes.bool,
    audioIsMuted: PropTypes.bool,
    callerMicIsMuted: PropTypes.bool,
    texts: PropTypes.any,
};

const mapStateToProps = state => {
    return {
        micIsMuted: state.conferencing.micIsMuted,
        audioStreamIsActive: state.application.audioStreamIsActive,
        audioIsMuted: state.application.audioIsMuted,
        callerMicIsMuted: state.application.callerMicIsMuted,
        texts: state.texts.texts,
    };
};

export default connect(mapStateToProps)(AudioPanel);
