import React from 'react';

function Contact() {
    return (
        <svg width="13.2" height="19.247" viewBox="0 0 13.2 19.247">
            <defs>
                <clipPath id="clip-path">
                    <rect
                        id="Rectangle_887"
                        data-name="Rectangle 887"
                        width="13.2"
                        height="19.247"
                    />
                </clipPath>
            </defs>
            <g
                id="Group_4768"
                data-name="Group 4768"
                transform="translate(0 0)">
                <g
                    id="Group_4768-2"
                    data-name="Group 4768"
                    transform="translate(0 0)">
                    <path
                        id="Path_3076"
                        data-name="Path 3076"
                        d="M10.312,7.367A3.684,3.684,0,1,0,6.628,3.684a3.684,3.684,0,0,0,3.684,3.684"
                        transform="translate(-3.712 0)"
                    />
                    <path
                        id="Path_3077"
                        data-name="Path 3077"
                        d="M9.24,19.744H3.96A3.964,3.964,0,0,0,0,23.7v6.16a.44.44,0,0,0,.44.44H12.76a.44.44,0,0,0,.44-.44V23.7a3.964,3.964,0,0,0-3.96-3.96"
                        transform="translate(0 -11.057)"
                    />
                </g>
            </g>
        </svg>
    );
}

export default Contact;
