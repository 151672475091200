import {
    ADD_CALLER_AUDIO_STREAM,
    ADD_CALLER_STREAM,
    ADD_CONFERENCE_USER_STREAM,
    ADD_OWN_STREAM_CONFERENCE_USER,
    ADD_DISPATCHER_AUDIO_STREAM,
    ADD_DISPATCHER_STREAM,
    ADD_DISPATCHER_BIDI_STREAM,
    REMOVE_CALLER_AUDIO_STREAM,
    REMOVE_CALLER_STREAM,
    REMOVE_CONFERENCE_USER_STREAM,
    REMOVE_OWN_STREAM_CONFERENCE_USER,
    REMOVE_DISPATCHER_AUDIO_STREAM,
    REMOVE_DISPATCHER_STREAM,
    REMOVE_DISPATCHER_BIDI_STREAM,
    RESET_ALL_STREAMS,
} from '../actions/stream';

export const ONLY_VIDEO_FACE_ENV = {
    facingMode: 'environment',
    audioInputId: null,
    constraints: {
        audio: false,
        video: true,
    },
};

export const ONLY_VIDEO = {
    audioInputId: null,
    constraints: {
        audio: false,
        video: true,
    },
};

export const ONLY_AUDIO = {
    videoInputId: null,
    constraints: {
        audio: true,
        video: false,
    },
};

export const ONLY_BIDI_VIDEO = {
    audioInputId: null,
    constraints: {
        audio: false,
        video: true,
    },
};

export const SCREENSHARING = {
    video: {
        cursor: 'always',
    },
    audio: false,
};

export const initialState = {
    dispatcherStream: null,
    dispatcherBidiStream: null,
    dispatcherAudioStream: null,
    conferenceUserStreams: [],
    ownStreamConferenceUser: null,
    callerStream: null,
    callerAudioStream: null,
};

const streamsReducer = (state = initialState, action) => {
    if (action.type === ADD_CALLER_STREAM) {
        return { ...state, callerStream: action.stream };
    }

    if (action.type === REMOVE_CALLER_STREAM) {
        return { ...state, callerStream: null };
    }

    if (action.type === ADD_CALLER_AUDIO_STREAM) {
        return { ...state, callerAudioStream: action.stream };
    }

    if (action.type === REMOVE_CALLER_AUDIO_STREAM) {
        return { ...state, callerAudioStream: null };
    }

    if (action.type === ADD_DISPATCHER_STREAM) {
        return { ...state, dispatcherStream: action.stream };
    }

    if (action.type === REMOVE_DISPATCHER_STREAM) {
        return { ...state, dispatcherStream: null };
    }

    if (action.type === ADD_DISPATCHER_BIDI_STREAM) {
        return { ...state, dispatcherBidiStream: action.stream };
    }

    if (action.type === REMOVE_DISPATCHER_BIDI_STREAM) {
        return { ...state, dispatcherBidiStream: null };
    }

    if (action.type === ADD_DISPATCHER_AUDIO_STREAM) {
        return { ...state, dispatcherAudioStream: action.stream };
    }

    if (action.type === REMOVE_DISPATCHER_AUDIO_STREAM) {
        return { ...state, dispatcherAudioStream: null };
    }

    if (action.type === ADD_CONFERENCE_USER_STREAM) {
        return {
            ...state,
            conferenceUserStreams: [...state.conferenceUserStreams, action.stream],
        };
    }

    if (action.type === REMOVE_CONFERENCE_USER_STREAM) {
        const remainingStreams = state.conferenceUserStreams.filter(stream => stream.streamId !== action.streamId);
        return { ...state, conferenceUserStreams: [...remainingStreams] };
    }

    if (action.type === ADD_OWN_STREAM_CONFERENCE_USER) {
        return {
            ...state,
            ownStreamConferenceUser: action.stream,
        };
    }

    if (action.type === REMOVE_OWN_STREAM_CONFERENCE_USER) {
        return {
            ...state,
            ownStreamConferenceUser: null,
        };
    }

    if (action.type === RESET_ALL_STREAMS) {
        return { ...initialState };
    }

    return state;
};

export default streamsReducer;
