import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { createKpiLog, parseTime } from '../../helper/helper';

import './ChatMessage.scss';
import { connect } from 'react-redux';
import ChatFile from './ChatFile';
import { DISPLAY_ONLY_IN_SESSION } from '../../config';
import { addNotificationAndShowDispatch } from '../../redux/actions/notifications';

/**
 * ChatMessage
 * Displays one chat message with timestamp and translation or original.
 */

class ChatMessage extends PureComponent {
    state = { showTranslated: true };
    toggleTranslated = () => {
        this.setState({ showTranslated: !this.state.showTranslated });
    };
    renderMessage = () => {
        if (this.props.fileShareFeature && this.props.dispatcherFiles.length !== 0) {
            let matchingFile = this.props.dispatcherFiles.filter(file => this.props.originalChatMessage === file.url + '\n' + file.description);
            if (matchingFile.length !== 0) {
                return (
                    <>
                        <ChatFile file={matchingFile[0]} download clickHandler={e => this.download(e, matchingFile[0])} />{this.handleTranslation()}
                    </>
                );
            }
        }

        if (this.props.fileShareFeature && this.props.callerFiles.length !== 0) {   
            let matchingFile = this.props.callerFiles.filter(file => this.props.originalChatMessage === file.url + '\n' + file.description);
            if (matchingFile.length !== 0) {
                return (
                <>
                    <ChatFile file={matchingFile[0]} download clickHandler={e => this.download(e, matchingFile[0])} />{this.handleTranslation()}
                </>
                );
            }
        }

        if (this.state.showTranslated) {
            return this.props.chatMessage;
        } else {
            return this.props.originalChatMessage;
        }  
    };
    handleTranslation = () => {
        const urlRegex = /https?:\/\/\S+/;
        if (this.state.showTranslated) {
            const msgWithoutUrl = this.props.chatMessage.replace(urlRegex, '');
            return msgWithoutUrl;
        } else {
            const msgWithoutUrl = this.props.originalChatMessage.replace(urlRegex, '');
            return msgWithoutUrl;
        }
    };
    download = async (e, file) => {
        e.preventDefault();
        if (this.props.currentUser === 'caller') {
            createKpiLog('fileDownloadCallerInfo', '', { 0: file.name, 1: file.size });
        } else {
            createKpiLog('fileDownloadDispatcherInfo', '', { 0: file.name, 1: file.size });
        }

        try {
            const fileUrl = file.url;
            const response = await fetch(fileUrl);
            if (!response.ok) {
                addNotificationAndShowDispatch('File not ready', 'error', DISPLAY_ONLY_IN_SESSION);
            }
            const content = await response.blob();
            const blob = new Blob([content], { type: file.type });
            const blobURL = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = blobURL;
            a.download = file.name;
            a.click();
            window.URL.revokeObjectURL(blobURL);
        } catch (error) {
            console.error('Error:', error);
        }
    };
    render() {
        const messageUser = this.props.id.split('_')[0];
        const messageFromCurrentUser = this.props.currentUser === messageUser;
        const classes = clsx('chatMessage', {
            'chatMessage--right': messageFromCurrentUser,
            'chatMessage--isInit': this.props.isInit,
        });
        const classesOriginal = clsx('chatMessage__showOriginal', {
            'chatMessage__showOriginal--strike': !this.state.showTranslated,
        });

        return (
            <div className={classes}>
                <div className="chatMessage__meta">
                    <span className="chatMessage__time">{parseTime(this.props.timestamp)}</span>
                </div>
                <div className="chatMessage__message">
                    {this.renderMessage()}
                    {/* remove any white space to ensure equality */}
                    {!messageFromCurrentUser && this.props.chatMessage.trim() !== this.props.originalChatMessage.trim() && (
                        <div className={classesOriginal} onClick={this.toggleTranslated}>
                            {this.props.showOriginal}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

// Map Redux State To Props
const mapStateToProps = state => {
    return {
        fileShareFeature: state.features.fileShareFeature,
        dispatcherFiles: state.files.dispatcherFiles,
        callerFiles: state.files.callerFiles,
    };
};

// PropTypes for this Component
ChatMessage.propTypes = {
    chatMessage: PropTypes.string,
    originalChatMessage: PropTypes.string,
    timestamp: PropTypes.number,
    id: PropTypes.string,
    currentUser: PropTypes.string,
    showOriginal: PropTypes.string,
    isInit: PropTypes.bool,
    fileShareFeature: PropTypes.bool,
    dispatcherFiles: PropTypes.array,
    callerFiles: PropTypes.array,
};

export default connect(mapStateToProps)(ChatMessage);
