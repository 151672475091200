import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { oauthStore } from '../../store/OAuthStore';
import { DEBUG } from '../../config';
import { store } from '../../store/DispatcherStore';
import { addLogDispatch } from '../../redux/actions/logs';

/**
 * Private route guard
 * checks for authentication
 * redirects if not authenticated
 */

const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {
                // if logged in
                if (oauthStore.loggedIn()) {
                    if (!store.connectedSession && rest.path.indexOf('new') === -1)
                        return (
                            // if session is not available and path doesnt contain new redirect to /new
                            <Redirect
                                to={{
                                    pathname: '/new',
                                }}
                            />
                        );
                    // if logged in and session available or logged in and path contains new render component
                    return <Component {...props} {...rest} />;
                }
                if (DEBUG)
                    addLogDispatch([
                        'No login found',
                        'oauthstore',
                        oauthStore,
                    ]);

                // if not logged in redirect to login
                return (
                    <Redirect
                        to={{
                            pathname: '/login',
                        }}
                    />
                );
            }}
        />
    );
};

// PropTypes for this Component
PrivateRoute.propTypes = {
    rest: PropTypes.any,
    component: PropTypes.any,
};

// Connect Props to Component
export default PrivateRoute;
